import { useMutation } from '@apollo/client';
import { useSnackbar } from '@myjobglasses/account-ui';
import { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { SignoutMutation, SignoutMutationVariables } from '../../@types/graphql';
import PageLoaderContext from '../../contexts/pageLoader';
import Routes from '../../Routes';
// import translations from './Signout.translations';
import { SIGNOUT } from './Signout.gql';
import { ErrorsTranslations } from 'i18n/errors.translations';

function Signout() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { /*success,*/ error } = useSnackbar();
  const { setLoading } = useContext(PageLoaderContext);

  const [signout, { loading }] = useMutation<SignoutMutation, SignoutMutationVariables>(SIGNOUT);

  useEffect(() => {
    signout()
      .then(() => {
        // success(intl.formatMessage(translations.success), {
        //   icon: 'check_circle',
        // });
        localStorage.removeItem('company_registration_token');
        navigate(Routes.home);
      })
      .catch(() => {
        error(intl.formatMessage(ErrorsTranslations.technical));
        navigate(Routes.home);
      });
  }, []);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return null;
}

export default Signout;
