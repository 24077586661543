import { PropsWithChildren, useEffect, useState } from 'react';
import './ProfileCompletionLayout.scss';
import { useNavigate } from 'react-router-dom';
import ProfileCompletionBar from './components/profile-completion-bar/ProfileCompletionBar';
import { PANELS } from './panels';
import { useProfileCompletionContext } from './ProfileCompletionContext.hook';
import AlertTriangleIcon from 'assets/component-icons/AlertTriangleIcon';
import Routes from 'Routes';
import Layout from 'components/layout/Layout';
import { AnimatePresence } from 'motion/react';
import { trackFinishCompletion, trackStartCompletion } from 'utils/ahoy_api';
import useMarketplaceRedirect from 'hooks/use-marketplace-redirect';
import { ProfileCompletionAside } from 'components/layout/asides/profile-completion/ProfileCompletionAside';

type TrackingsFields = {
  userId: string;
  currentStepSlug: string;
  currentStepNumber: number;
  previousDisplayedStepSlug?: string;
  previousDisplayedStepNumber?: number;
  numberOfSkippedStepsSincePreviousStep?: number;
  slugOfSkippedStepsSincePreviousStep?: string[];
  startedAt: string;
  finishedAt: string | null;
};

export default function ProfileCompletionLayout({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { getRedirectUrl } = useMarketplaceRedirect();

  const { currentPanelIndex, completion, user, setCurrentPanelIndex, setCompletion } = useProfileCompletionContext();

  const [previousTrack, setPreviousTrack] = useState<TrackingsFields>();

  const computeStart = (previousTrack?: TrackingsFields): TrackingsFields => {
    const data: TrackingsFields = {
      userId: user?.id || '',
      currentStepSlug: PANELS[currentPanelIndex].path,
      currentStepNumber: currentPanelIndex,
      previousDisplayedStepSlug: previousTrack?.currentStepSlug,
      previousDisplayedStepNumber: previousTrack?.currentStepNumber,
      numberOfSkippedStepsSincePreviousStep: previousTrack && currentPanelIndex - previousTrack.currentStepNumber,
      slugOfSkippedStepsSincePreviousStep:
        previousTrack &&
        PANELS.slice(previousTrack.currentStepNumber + 1, currentPanelIndex).map((panel) => panel.path),
      startedAt: new Date().toISOString(),
      finishedAt: null,
    };
    trackStartCompletion(data);
    return data;
  };

  const computeEnd = (previousTrack: TrackingsFields): TrackingsFields => {
    const data = {
      ...previousTrack,
      finishedAt: new Date().toISOString(),
    };
    trackFinishCompletion(data);
    return data;
  };

  useEffect(() => {
    if (!user) return;

    if (user?.stepByStepCompletionStatus?.fieldsCompletion) {
      const currentPanel = PANELS[currentPanelIndex];
      if (!currentPanel.isComplete(user?.stepByStepCompletionStatus?.fieldsCompletion)) {
        setCompletion((currentPanelIndex / (PANELS.length - 1)) * 100);
        if (previousTrack) computeEnd(previousTrack);
        setPreviousTrack((newTrack) => computeStart(newTrack));
        navigate(`${Routes.profileCompletion}${PANELS[currentPanelIndex].path}`);
      } else {
        if (currentPanelIndex < PANELS.length - 1) setCurrentPanelIndex((prev) => prev + 1);
        else {
          window.location.href = getRedirectUrl();
        }
      }
    }
  }, [currentPanelIndex, user?.stepByStepCompletionStatus?.fieldsCompletion, user?.showWelcomePanel]);

  return (
    <Layout aside={<ProfileCompletionAside />}>
      <div className="profile-completion-layout">
        {PANELS[currentPanelIndex].wip && (
          <div className="profile-completion-layout__wip">
            <AlertTriangleIcon />
            {`WIP : ${PANELS[currentPanelIndex].wip}`}
          </div>
        )}
        <header className="profile-completion-layout__header">
          <AnimatePresence>
            {!PANELS[currentPanelIndex].hideCompletionBar && (
              <ProfileCompletionBar
                icon={PANELS[currentPanelIndex].icon}
                value={completion}
              />
            )}
          </AnimatePresence>
        </header>

        <main className="profile-completion-layout__main">{children}</main>
      </div>
    </Layout>
  );
}
