enum Routes {
  home = '/',
  segmentation = '/segmentation',
  crash = '/crash',
  signup = '/signup',
  flowSignup = '/:flow/signup',
  confirmSignup = '/confirm-signup',
  confirmedEmail = '/confirmed-email',
  profileCompletion = '/profile-completion',
  profile = '/profile',
  signout = '/signout',
  newPassword = '/new-password',
  confirmNewPassword = '/confirm-new-password',
  requestNewPassword = '/request-new-password',
  confirmEmail = '/confirm-email',
  confirmTutorEmail = '/confirm-tutor-email',
  mustConfirmEmail = '/must-confirm-email',
  mustProvideTutorEmail = '/must-provide-tutor-email',
  companyAlreadyCustomer = '/company-already-customer',

  // Company admin
  companyAdminProfile = '/company-admin/profile/:id',
  hrAdminSignup = '/company-admin/signup',

  // OAuth
  oAuthFranceTravail = '/oauth/francetravail/callback',
  oAuthLinkedIn = '/oauth/linkedin/callback',
  oAuthGoogle = '/oauth/google/callback',
}

export default Routes;
