import { gql } from '@apollo/client';

export const SIGN_UP_MUTATION = gql`
  mutation SIGN_UP(
    $birthdate: ISO8601Date
    $email: String!
    $invitationToken: String
    $companyRegistrationToken: String
    $oauthNonce: String
    $orianeAmbassador: Boolean
    $highSchoolTeacher: Boolean
    $tutorEmail: String
    $comesFrom: ComesFromEnum
    $vocationCoachSignup: Boolean
    $password: String
    $draftAttributionToken: String
  ) {
    signup(
      birthdate: $birthdate
      email: $email
      invitationToken: $invitationToken
      companyRegistrationToken: $companyRegistrationToken
      oauthNonce: $oauthNonce
      orianeAmbassador: $orianeAmbassador
      highSchoolTeacher: $highSchoolTeacher
      tutorEmail: $tutorEmail
      comesFrom: $comesFrom
      vocationCoachSignup: $vocationCoachSignup
      password: $password
      draftAttributionToken: $draftAttributionToken
    ) {
      email
    }
  }
`;
