import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import { ComponentType, DetailedHTMLProps, FocusEvent, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import './TextInputWithChecker.scss';
import LoadingIcon from 'assets/component-icons/LoadingIcon';
import CheckIcon from 'assets/component-icons/CheckIcon';
import XIcon from 'assets/component-icons/XIcon';

const TRANSLATIONS = defineMessages({
  check: {
    id: 'TextInputWithChecker.check',
    defaultMessage: 'Vérifier',
  },
});

export type TextInputWithCheckerStatus = 'error' | 'checked' | 'loading';
// eslint-disable-next-line max-len
type DefaultInputProps = Pick<
  DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'onFocus' | 'onBlur' | 'placeholder'
>;
type Props = DefaultInputProps & {
  icon?: ComponentType<IconProps>;
  name: string;
  value?: string;
  label?: string;
  error?: string;
  dataCy?: string;
  status?: TextInputWithCheckerStatus;
  onCheck: () => void;
};

export default function TextInputWithChecker({
  icon: Icon,
  name,
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  error,
  dataCy,
  status,
  onCheck,
}: Props) {
  const intl = useIntl();

  const [isFocused, setIsFocused] = useState(false);

  function handleFocus(event: FocusEvent<HTMLInputElement>) {
    setIsFocused(true);
    onFocus?.(event);
  }

  function handleBlur(event: FocusEvent<HTMLInputElement>) {
    setIsFocused(false);
    onBlur?.(event);
  }

  return (
    <div className="text-input-with-checker">
      <div className="text-input-with-checker__header">
        {label && (
          <label
            htmlFor={name}
            className={classNames({ error: error })}
          >
            {label}
          </label>
        )}
      </div>

      <div
        className={classNames('text-input-with-checker__main', {
          'text-input-with-checker__main--focused': isFocused,
          'text-input-with-checker__main--error': error,
        })}
      >
        {Icon && <Icon className="text-input-with-checker__main__icon" />}
        <input
          data-cy={dataCy}
          id={name}
          type="text"
          {...{
            name,
            value,
            onChange,
            placeholder,
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        <div
          className="text-input-with-checker__main__actions"
          style={{ flex: 'none' }}
        >
          {status === 'loading' && <LoadingIcon />}
          {status === 'checked' && <CheckIcon style={{ stroke: '#31A55F' }} />}
          {status === 'error' && <XIcon style={{ stroke: '#E02020' }} />}

          <button
            type="button"
            tabIndex={-1}
            onClick={onCheck}
          >
            {intl.formatMessage(TRANSLATIONS.check)}
          </button>
        </div>
      </div>

      {error && (
        <span
          data-cy={dataCy && `${dataCy}__error`}
          className="text-input-with-checker__error"
        >
          {error}
        </span>
      )}
    </div>
  );
}
