import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react'

const opts = {
  uri: `${window.__RUNTIME_CONFIG__.REACT_APP_HERMES_URL || ''}/graphql`,
  credentials: 'include',
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.captureException(new Error(`[GraphQL error] ${message}`), {
        extra: {
          operationName: operation.operationName,
          locations,
          path,
        },
      });
    });
  }

  if (networkError) {
    Sentry.captureException(new Error(`[Network error] ${networkError}`));
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: BatchHttpLink.from([
    new ApolloLink((operation, forward) => {
      const definition = operation.query.definitions[0] as any;
      return forward(operation);
    }),
    errorLink,
    createUploadLink(opts) as unknown as ApolloLink,
    new BatchHttpLink(opts),
  ]),
});

export default client;
