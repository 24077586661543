import { useContext, useMemo, useState } from 'react';
import { PrimarySituation, PrimarySituationEnum, FieldCompletionName } from '../../../@types/graphql';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import { UserContext } from 'contexts/user/UserContext';
import ProfessionalSituationPanel from './ProfessionalSituationPanel';
import StudentSituationPanel from './StudentSituationPanel';
import SelectInput from 'components/select-input/SelectInput';
import OtherSituationPanel from './OtherSituationPanel';
import SchoolCoachSituationPanel from './SchoolCoachSituationPanel';
import FranceTravailSupportSituationPanel from './FranceTravailSupportSituationPanel';

export default function SituationPanel() {
  const { user } = useContext(UserContext);
  const settingsContext = useContext(SettingsContext);

  const [primarySituation, setPrimarySituation] = useState<PrimarySituationEnum>(
    user?.primarySituation?.key || PrimarySituationEnum.Other,
  );

  const situations: PrimarySituation[] = useMemo(
    () => settingsContext?.settings?.primarySituations || [],
    [settingsContext?.settings],
  );

  const renderComplementaryPanel = useMemo(() => {
    switch (primarySituation) {
      case PrimarySituationEnum.Worker:
        return <ProfessionalSituationPanel primarySituation={primarySituation} />;
      case PrimarySituationEnum.Student:
        return <StudentSituationPanel primarySituationProps={primarySituation} />;
      case PrimarySituationEnum.PostBacStudentSupport:
      case PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport:
        return <SchoolCoachSituationPanel primarySituationProps={primarySituation} />;
      case PrimarySituationEnum.JobSeekerSupport:
        return <FranceTravailSupportSituationPanel primarySituationProps={primarySituation} />;
      default:
        return <OtherSituationPanel primarySituation={primarySituation} />;
    }
  }, [primarySituation]);

  const isPrimarySituationDisabled = user?.editionCompletionStatus?.fieldsCompletion?.some(
    (completionStatus) => completionStatus.name === FieldCompletionName.PrimarySituation && completionStatus.disabled,
  );
  const initialSituation = situations.find(({ key }) => key === primarySituation);

  return (
    <>
      <form>
        <SelectInput
          key={primarySituation}
          name="primarySituation"
          disabled={isPrimarySituationDisabled}
          values={situations.map(({ key, name }) => ({
            value: key,
            translation: name,
          }))}
          value={initialSituation?.key || PrimarySituationEnum.Other}
          onChange={setPrimarySituation}
        />
        {renderComplementaryPanel}
      </form>
    </>
  );
}
