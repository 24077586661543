import React, { CSSProperties, Fragment, useCallback } from 'react';

import './BadgeRadioInput.scss';
import { InputValue } from 'components/types/BaseInputValue';

type BadgeRadioInputProps<T> = {
  label?: string;
  name: string;
  value?: T[];
  values: InputValue<T>[];
  error?: string;
  onSelection: (value: T[]) => void;
  style?: CSSProperties;
};

export default function BadgeRadioInput<T extends string>({
  label,
  name,
  error,
  value,
  values,
  onSelection,
  style,
}: BadgeRadioInputProps<T>) {
  const onChange = useCallback(
    (v: T) => {
      onSelection(value?.includes(v) ? value.filter((val) => val !== v) : [...(value || []), v]);
    },
    [value, onSelection]
  );

  return (
    <div
      className="badge-radio"
      style={style}
    >
      {label && (
        <label
          className="badge-radio__label"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className="badge-radio__input">
        {values.map(({ translation, value: v }) => (
          <Fragment key={v}>
            <input
              type="checkbox"
              name={name}
              id={v}
              value={v}
              checked={value?.includes(v)}
              onChange={() => onChange(v)}
            />
            <label
              key={v}
              htmlFor={v}
            >
              {translation}
            </label>
          </Fragment>
        ))}
      </div>
      {error && <div className="radio-input__error">{error}</div>}
    </div>
  );
}
