import React, { memo, useContext, useMemo } from 'react';
import { ProfileCompletionContext } from 'scenes/profile-completion/ProfileCompletionContext';
import Skeleton from 'react-loading-skeleton';

import './ProfileCompletionAside.scss';
import AvatarIcon from 'assets/component-icons/AvatarIcon';
import {
  GradeEnum,
  Language,
  PrimarySituationEnum,
  ProfileCompletionUserPartsFragment,
} from '../../../../@types/graphql';
import { defineMessages, useIntl } from 'react-intl';
import { GradeEnumTranslations } from 'i18n/grade.translations';
import { LanguageToCountryFlag } from 'constants/languages.constant';
import { useLocation } from 'react-router-dom';
import { AmbassadorCard } from './components/ambassador-card/AmbassadorCard';
import { AmbassadorView } from './components/ambassador-view/AmbassadorView';

const TRANSLATIONS = defineMessages({
  presentation: {
    id: 'ProfileCompletionAside.title',
    defaultMessage: 'Présentation',
  },
  // wts = WantToShare
  wtsTitle: {
    id: 'ProfileCompletionAside.wantToShare.title',
    defaultMessage: 'Apparaissez vous aussi dans notre moteur de recherche',
  },
  wtsSubtitle: {
    id: 'ProfileCompletionAside.wantToShare.subtitle',
    defaultMessage: 'Et aidez nos Membres à trouver leur voie',
  },
});

export const ProfileCompletionAside = memo(() => {
  const { user } = useContext(ProfileCompletionContext);
  const location = useLocation();

  if (!user) return null;

  if (location.pathname.includes('want-to-share')) return <WantToShareAside user={user} />;
  if (user.wantToShare === false) return <MemberFlow user={user} />;
  else return <AmbassadorFlow user={user} />;
});

type AsideProps = {
  user: Partial<ProfileCompletionUserPartsFragment>;
};

const MemberFlow: React.FC<AsideProps> = ({ user }) => {
  const {
    avatars,
    firstName,
    lastName,
    languages,
    primarySituation,
    jobTitle,
    currentGrade,
    preparedDiplomaTitle,
    currentSchoolName,
    companyName,
    meetingPlace,
    memberPresentation,
  } = user;

  return (
    <div className="profile-completion-aside">
      <Avatar url={avatars?.url} />
      <Name {...{ firstName, lastName, languages }} />
      <div className="profile-completion-aside__infos">
        <Situation
          primarySituation={primarySituation?.key}
          jobTitle={jobTitle}
          currentGrade={currentGrade}
          preparedDiplomaTitle={preparedDiplomaTitle}
        />
        <Establishment
          primarySituation={primarySituation?.key}
          currentSchoolName={currentSchoolName}
          companyName={companyName}
        />
        <MeetingPlace meetingPlace={meetingPlace} />
      </div>
      <MemberPresentation presentation={memberPresentation} />
    </div>
  );
};

const WantToShareAside: React.FC<AsideProps> = ({ user }) => {
  const { formatMessage } = useIntl();
  return (
    <div className="profile-completion-aside--want-to-share">
      <AmbassadorCard style={{ position: 'absolute', transform: 'translate(-50%, -50%) scale(0.9)' }} />
      <AmbassadorCard
        style={{ position: 'absolute', transform: 'translate(50%, -50%) scale(0.9)' }}
        premium
      />
      <AmbassadorCard user={user} />

      <div className="profile-completion-aside--want-to-share__title">{formatMessage(TRANSLATIONS.wtsTitle)}</div>
      <p>{formatMessage(TRANSLATIONS.wtsSubtitle)}</p>
    </div>
  );
};

const AmbassadorFlow: React.FC<AsideProps> = ({ user }) => {
  return <AmbassadorView user={user} />;
};

const Avatar: React.FC<{ url?: string | null }> = ({ url }) => {
  if (!url)
    return <AvatarIcon className="profile-completion-aside__avatar profile-completion-aside__avatar--placeholder" />;
  return (
    <img
      className="profile-completion-aside__avatar"
      src={url}
    />
  );
};

type NameProps = {
  firstName?: string | null;
  lastName?: string | null;
  languages?: Language[] | null;
};

const Name: React.FC<NameProps> = ({ firstName, lastName, languages }) => {
  if (!lastName && !firstName)
    return (
      <Skeleton
        width={96}
        height={16}
      />
    );
  return (
    <p className="profile-completion-aside__name">
      {firstName} {lastName} {languages?.map((l) => LanguageToCountryFlag[l])}
    </p>
  );
};

type SituationProps = {
  primarySituation?: PrimarySituationEnum | null;
  jobTitle?: string | null;
  currentGrade?: GradeEnum | null;
  preparedDiplomaTitle?: string | null;
};

const Situation: React.FC<SituationProps> = ({ primarySituation, jobTitle, currentGrade, preparedDiplomaTitle }) => {
  const { formatMessage } = useIntl();
  const isStudent = useMemo(() => primarySituation === PrimarySituationEnum.Student, [primarySituation]);
  const shouldShowSkeleton = useMemo(() => {
    if (isStudent) return !currentGrade && !preparedDiplomaTitle;
    return !jobTitle;
  }, [primarySituation, jobTitle, currentGrade, preparedDiplomaTitle]);

  if (shouldShowSkeleton)
    return (
      <Skeleton
        width={148}
        height={16}
      />
    );

  if (isStudent)
    return (
      <p>
        {formatMessage(GradeEnumTranslations[currentGrade!])} {preparedDiplomaTitle && `- ${preparedDiplomaTitle}`}
      </p>
    );

  return <p>{jobTitle}</p>;
};

type EstablishmentProps = {
  primarySituation?: PrimarySituationEnum | null;
  currentSchoolName?: string | null;
  companyName?: string | null;
};

const Establishment: React.FC<EstablishmentProps> = ({ primarySituation, currentSchoolName, companyName }) => {
  const isStudent = useMemo(() => primarySituation === PrimarySituationEnum.Student, [primarySituation]);
  const shouldShowSkeleton = useMemo(() => {
    if (isStudent) return !currentSchoolName;
    return !companyName;
  }, [primarySituation, currentSchoolName, companyName]);

  if (shouldShowSkeleton)
    return (
      <Skeleton
        width={148}
        height={16}
      />
    );

  return <p>{isStudent ? currentSchoolName : companyName}</p>;
};

const MeetingPlace: React.FC<{ meetingPlace?: string | null }> = ({ meetingPlace }) => {
  if (!meetingPlace)
    return (
      <Skeleton
        width={148}
        height={16}
      />
    );

  return (
    <p>
      <u>{meetingPlace}</u>
    </p>
  );
};

const MemberPresentation: React.FC<{ presentation?: string | null }> = ({ presentation }) => {
  const { formatMessage } = useIntl();
  return (
    <div className="profile-completion-aside__presentation">
      <div className="profile-completion-aside__presentation__title">{formatMessage(TRANSLATIONS.presentation)}</div>

      {presentation ? (
        <p>{presentation}</p>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
          <Skeleton height={16} />
          <Skeleton height={16} />
          <Skeleton height={16} />
          <Skeleton height={16} />
        </div>
      )}
    </div>
  );
};
