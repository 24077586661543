import MailIcon from 'assets/component-icons/MailIcon';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import TextInput from 'components/text-input/TextInput';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { Location, useLocation } from 'react-router-dom';
import useAuth from 'scenes/authentication/Auth.hook';
import translations from './MustProvideTutorEmail.translations';
import { AuthenticationAside } from 'components/layout/asides/authentication/AuthenticationAside';

type FormikFields = {
  email: string;
  birthdate: Date;
  tutorEmail: string;
};

type LocationState = {
  email: string;
  birthdate: Date;
};

export default function MustProvideTutorEmail() {
  const intl = useIntl();
  const location = useLocation() as Location<LocationState>;

  const auth = useAuth();

  const formik = useFormik<FormikFields>({
    initialValues: {
      email: location.state.email,
      birthdate: location.state.birthdate,
      tutorEmail: '',
    },
    onSubmit: ({ email, birthdate, tutorEmail }) => {
      auth({ email, birthdate, tutorEmail, flow: 'signup' });
    },
  });

  return (
    <Layout aside={<AuthenticationAside />}>
      <LayoutHeader>
        <h1>{intl.formatMessage(translations.title)}</h1>
        <p>{intl.formatMessage(translations.subtitle)}</p>
      </LayoutHeader>

      <main>
        <form>
          <TextInput
            dataCy="email"
            icon={MailIcon}
            label={intl.formatMessage(translations.emailLabel)}
            name="tutorEmail"
            type="email"
            value={formik.values.tutorEmail}
            onChange={formik.handleChange}
          />

          <PrimaryButton
            label={intl.formatMessage(translations.buttonText)}
            onClick={formik.handleSubmit}
          />
        </form>
      </main>
    </Layout>
  );
}
