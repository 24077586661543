import classNames from 'classnames';
import React, { CSSProperties, useMemo } from 'react';

import './AmbassadorCard.scss';
import { PrimarySituationEnum, ProfileCompletionUserPartsFragment } from '../../../../../../@types/graphql';
import MessageTextSquareIcon from 'assets/component-icons/MessageTextSquareIcon';

import { defineMessages, useIntl } from 'react-intl';
import ThumbsUpIcon from 'assets/component-icons/ThumbsUpIcon';
import BuildingIcon from 'assets/component-icons/BuildingIcon';
import AvatarIcon from 'assets/component-icons/AvatarIcon';
import Skeleton from 'react-loading-skeleton';

export const AmbassadorCardTranslations = defineMessages({
  available: {
    id: 'AmbassadorCard.available',
    defaultMessage: 'Disponible',
  },
  unavailable: {
    id: 'AmbassadorCard.unavailable',
    defaultMessage: 'Indisponible',
  },
});

type Props = {
  user?: Partial<ProfileCompletionUserPartsFragment>;
  unavailable?: boolean;
  premium?: boolean;
  style?: CSSProperties;
};

export const AmbassadorCard: React.FC<Props> = ({ user, unavailable, premium, style }) => {
  const { formatMessage } = useIntl();
  const isStudent = useMemo(
    () => user?.primarySituation?.key === PrimarySituationEnum.Student,
    [user?.primarySituation],
  );

  return (
    <div
      className="ambassador-card"
      data-cy="ambassador-card"
      style={style}
    >
      <header className={classNames('ambassador-card__header', { 'ambassador-card__header--premium': premium })} />
      <div className="ambassador-card__content">
        {user?.avatars?.url ? (
          <img
            className="ambassador-card__content__avatar"
            src={user.avatars?.url || ''}
          />
        ) : (
          <AvatarIcon
            className="ambassador-card__content__avatar"
            secondaryColor="#DDDDDD"
            primaryColor="#ffffff"
          />
        )}
        <div className="ambassador-card__content__company-logo">
          <BuildingIcon />
        </div>

        <div className="ambassador-card__content__name">
          {user?.firstName || user?.lastName ? (
            <>
              {user?.firstName} {user?.lastName}
            </>
          ) : (
            <Skeleton />
          )}
        </div>

        <div
          className="ambassador-card__content__company"
          data-cy="ambassador-card__company-name"
        >
          {user ? <>{isStudent ? user.currentSchoolName : user.companyName}</> : <Skeleton />}
        </div>

        <div className="ambassador-card__content__position">
          {user ? <>{isStudent ? user.preparedDiplomaTitle : user.jobTitle}</> : <Skeleton />}
        </div>

        <footer className="ambassador-card__footer">
          <div
            className={classNames('ambassador-card__footer__availability', {
              'ambassador-card__footer__availability--premium': premium,
            })}
          >
            <div
              className={classNames('ambassador-card__footer__availability__icon', {
                'ambassador-card__footer__availability__icon--premium': premium,
              })}
            >
              <MessageTextSquareIcon />
            </div>

            {formatMessage(
              !unavailable ? AmbassadorCardTranslations.available : AmbassadorCardTranslations.unavailable,
            )}
          </div>

          {premium && (
            <div className={classNames('ambassador-card__footer__likes')}>
              5
              <div className="ambassador-card__footer__likes__icon">
                <ThumbsUpIcon />
              </div>
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};
