import { defineMessages } from 'react-intl';

export const PanelCategoriesTranslations = defineMessages({
  personalInformations: {
    id: 'Profile.PanelCategoriesTranslations.personalInformations',
    defaultMessage: 'Informations personnelles',
  },
  ambassadorInformations: {
    id: 'Profile.PanelCategoriesTranslations.ambassadorInformations',
    defaultMessage: 'Informations Ambassadeur',
  },
});

export const PanelErrorTranslations = defineMessages({
  inputEmpty: {
    id: 'Profile.PanelErrorTranslations.inputEmpty',
    defaultMessage: 'Ce champ est obligatoire',
  },
  passwordMismatch: {
    id: 'Profile.PanelErrorTranslations.passwordMismatch',
    defaultMessage: 'Les mots de passe ne correspondent pas',
  },
  inputTooShort: {
    id: 'Profile.PanelErrorTranslations.inputTooShort',
    defaultMessage: 'Ce champ est trop court',
  },
  invalidPhoneNumber: {
    id: 'Profile.PanelErrorTranslations.invalidPhoneNumber',
    defaultMessage: 'Format de numéro de téléphone invalide',
  },
  endDateInFuture: {
    id: 'Profile.PanelErrorTranslations.endDateInFuture',
    defaultMessage: 'La date de début de carrière doit être dans le passé',
  },
  invalidGradeEnum: {
    id: 'PanelErrorTranslations.invalidGradeEnum',
    defaultMessage: "Niveau(x) d'étude(s) invalide(s)",
  },
});

export const IdentityPanelTranslations = defineMessages({
  title: {
    id: 'Profile.IdentityPanelTranslations.title',
    defaultMessage: 'Votre identité',
  },
  subtitle: {
    id: 'Profile.IdentityPanelTranslations.subtitle',
    defaultMessage: 'Modifiez ici les informations liées à votre identité.',
  },

  gender: {
    id: 'Profile.IdentityPanelTranslations.gender',
    defaultMessage: 'Genre',
  },
  genderPlaceholder: {
    id: 'Profile.IdentityPanelTranslations.genderPlaceholder',
    defaultMessage: 'Sélectionnez votre genre',
  },
  firstName: {
    id: 'Profile.IdentityPanelTranslations.firstName',
    defaultMessage: 'Prénom',
  },
  firstNamePlaceholder: {
    id: 'Profile.IdentityPanelTranslations.firstNamePlaceholder',
    defaultMessage: 'Gabriel',
  },
  lastName: {
    id: 'Profile.IdentityPanelTranslations.lastName',
    defaultMessage: 'Nom',
  },
  lastNamePlaceholder: {
    id: 'Profile.IdentityPanelTranslations.lastNamePlaceholder',
    defaultMessage: 'Martin',
  },
  birthdate: {
    id: 'Profile.IdentityPanelTranslations.birthdate',
    defaultMessage: 'Date de naissance',
  },
  tutorEmailConfirmed: {
    id: 'Profile.IdentityPanelTranslations.tutorEmailConfirmed',
    defaultMessage: 'Email du responsable légal ({tutorEmail}) confirmé le {at}',
  },
});

export const PrimarySituationPanelTranslations = defineMessages({
  title: {
    id: 'Profile.PrimarySituationPanelTranslations.title',
    defaultMessage: 'Renseignez votre situation actuelle',
  },
  subtitle: {
    id: 'Profile.PrimarySituationPanelTranslations.subtitle',
    defaultMessage: 'Sélectionnez votre situation actuelle dans le menu déroulant.',
  },
});

export const CurrentEducationPanelTranslations = defineMessages({
  title: {
    id: 'Profile.CurrentEducationPanelTranslations.title',
    defaultMessage: 'Votre formation',
  },
  subtitle: {
    id: 'Profile.CurrentEducationPanelTranslations.subtitle',
    defaultMessage: 'Renseignez les informations suivantes sur la formation que vous suivez actuellement',
  },

  secondarySituation: {
    id: 'Profile.CurrentEducationPanelTranslations.secondarySituation',
    defaultMessage: "Type d'établissement",
  },
  currentGradeLabel: {
    id: 'Profile.CurrentEducationPanelTranslations.currentGradeLabel',
    defaultMessage: "Niveau d'étude",
  },
  currentGradePlaceholder: {
    id: 'Profile.CurrentEducationPanelTranslations.currentGradePlaceholder',
    defaultMessage: '1ère année',
  },
  currentSchoolNameLabel: {
    id: 'Profile.CurrentEducationPanelTranslations.currentSchoolNameLabel',
    defaultMessage: 'Votre établissement',
  },
  currentSchoolNamePlaceholder: {
    id: 'Profile.CurrentEducationPanelTranslations.currentSchoolNamePlaceholder',
    defaultMessage: 'Entrez le nom ou la ville de votre établissement',
  },
  currentSchoolCityLabel: {
    id: 'Profile.CurrentEducationPanelTranslations.currentSchoolCityLabel',
    defaultMessage: 'Ville',
  },
  currentSchoolCityPlaceholder: {
    id: 'Profile.CurrentEducationPanelTranslations.currentSchoolCityPlaceholder',
    defaultMessage: 'Paris',
  },
  currentScholarityStartAt: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentScholarityStartAt',
    defaultMessage: 'Début',
  },
  currentScholarityEndAt: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentScholarityEndAt',
    defaultMessage: 'Fin prévue',
  },
  departmentLabel: {
    id: 'Profile.CurrentEducationPanelTranslations.departmentLabel',
    defaultMessage: "Département de l'établissement",
  },
  labelInputApprentice: {
    id: 'Profile.CurrentEducationPanelTranslations.labelInputApprentice',
    defaultMessage: 'Suivez-vous cette formation en apprentissage/alternance ?',
  },
  diplomaLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.diplomaLabel',
    defaultMessage: 'Titre du diplôme préparé',
  },
  emptySchoolNameMessage: {
    id: 'Profile.CurrentEducationPanelTranslations.emptySchoolNameMessage',
    defaultMessage: "Pas de résultat. Cliquez ici si vous n'avez pas trouvé votre établissement dans la liste",
  },
});

export const ProfessionalSituationPanelTranslations = defineMessages({
  title: {
    id: 'Profile.ProfessionalSituationPanelTranslations.title',
    defaultMessage: 'Votre situation professionnelle',
  },
  subtitle: {
    id: 'Profile.ProfessionalSituationPanelTranslations.subtitle',
    defaultMessage: 'Veuillez compléter les informations relatives à votre poste actuel.',
  },

  job: {
    id: 'Profile.ProfessionalSituationPanelTranslations.job',
    defaultMessage: 'Poste actuel',
  },
  jobPlaceholder: {
    id: 'Profile.ProfessionalSituationPanelTranslations.jobPlaceholder',
    defaultMessage: 'Ingénieur',
  },
  companyName: {
    id: 'Profile.ProfessionalSituationPanelTranslations.companyName',
    defaultMessage: "Nom de l'entreprise",
  },
  companyNameWarning: {
    id: 'Profile.ProfessionalSituationPanelTranslations.companyNameWarning',
    defaultMessage: 'La modification de votre entreprise entraînera la dépublication de votre profil et sa modération.',
  },
  companyNamePlaceholder: {
    id: 'Profile.ProfessionalSituationPanelTranslations.companyNamePlaceholder',
    defaultMessage: 'My Job Glasses',
  },
  yearsOfExperience: {
    id: 'Profile.ProfessionalSituationPanelTranslations.yearsOfExperience',
    defaultMessage: "Années d'expérience",
  },
});

export const OtherSituationPanelTranslations = defineMessages({
  jobTitle: {
    id: 'Profile.OtherSituationPanelTranslations.jobTitle',
    defaultMessage: 'Dernier poste occupé',
  },
  companyName: {
    id: 'Profile.OtherSituationPanelTranslations.companyName',
    defaultMessage: "Nom de l'entreprise",
  },
  yearsOfExperience: {
    id: 'Profile.OtherSituationPanelTranslations.yearsOfExperience',
    defaultMessage: "Années d'expérience",
  },
  neverWorked: {
    id: 'Profile.OtherSituationPanelTranslations.neverWorked',
    defaultMessage: "Je n'ai jamais travaillé",
  },
});

export const MaxMeetingsPerMonthPanelTranslations = defineMessages({
  title: {
    id: 'Profile.MaxMeetingsPerMonthPanelTranslations.title',
    defaultMessage: 'Limite de rendez-vous',
  },
  subtitle: {
    id: 'Profile.MaxMeetingsPerMonthPanelTranslations.subtitle',
    defaultMessage: 'Le nombre de rendez-vous maximum que vous souhaitez effectuer par mois',
  },

  quota: {
    id: 'Profile.MaxMeetingsPerMonthPanelTranslations.quota',
    defaultMessage: 'Mon nombre de rendez-vous par mois',
  },
  quotaOption: {
    id: 'Profile.MaxMeetingsPerMonthPanelTranslations.quotaOption',
    defaultMessage: 'rendez-vous par mois',
  },
});

export const MemberPresentationPanelTranslations = defineMessages({
  title: {
    id: 'Profile.MemberPresentationPanelTranslations.title',
    defaultMessage: 'Votre profil Membre',
  },
  subtitle: {
    id: 'Profile.MemberPresentationPanelTranslations.subtitle',
    defaultMessage:
      'Renseignez ici votre présentation. Celle-ci ne sera visible que par les personnes que vous décidez de contacter.',
  },
});

export const PastEducationsPanelTranslations = defineMessages({
  title: {
    id: 'Profile.PastEducationsPanelTranslations.title',
    defaultMessage: 'Vos diplômes',
  },
  subtitle: {
    id: 'Profile.PastEducationsPanelTranslations.subtitle',
    defaultMessage: 'Précisez les diplômes obtenus et les formations que vous avez effectuées.',
  },
  openModalButton: {
    id: 'Profile.PastEducationsPanelTranslations.openModalButton',
    defaultMessage: 'Ajouter une formation passée',
  },
  addModalTitle: {
    id: 'Profile.PastEducationsPanelTranslations.addModalTitle',
    defaultMessage: 'Ajouter un diplôme ou une formation',
  },
  editModalTitle: {
    id: 'Profile.PastEducationsPanelTranslations.editModalTitle',
    defaultMessage: 'Edition',
  },
  addModalDiplomaType: {
    id: 'Profile.PastEducationsPanelTranslations.addModalDiplomaType',
    defaultMessage: 'Niveau',
  },
  addModalDiplomaTitle: {
    id: 'Profile.PastEducationsPanelTranslations.addModalDiplomaTitle',
    defaultMessage: 'Intitulé du diplôme ou de la formation',
  },
  addModalSchoolName: {
    id: 'Profile.PastEducationsPanelTranslations.addModalSchoolName',
    defaultMessage: 'Établissement',
  },
  addModalPeriodEnd: {
    id: 'Profile.PastEducationsPanelTranslations.addModalPeriodEnd',
    defaultMessage: 'Année d\'obtention du diplôme',
  },
});

export const AmbassadorPresentationPanelTranslations = defineMessages({
  title: {
    id: 'Profile.AmbassadorPresentationPanelTranslations.title',
    defaultMessage: 'Votre profil Ambassadeur',
  },
  subtitle: {
    id: 'Profile.AmbassadorPresentationPanelTranslations.subtitle',
    defaultMessage: 'Complétez cette série de questions afin de construire votre profil Ambassadeur.',
  },
});

export const MeetingPreferencesPanelTranslations = defineMessages({
  title: {
    id: 'Profile.MeetingPreferencesPanelTranslations.title',
    defaultMessage: 'Paramètres de rencontres',
  },
  subtitle: {
    id: 'Profile.MeetingPreferencesPanelTranslations.subtitle',
    defaultMessage: "Indiquez les types d'utilisateurs avec lesquels vous souhaitez échanger.",
  },
  acceptGroupConversation: {
    id: 'Profile.MeetingPreferencesPanelTranslations.canAcceptGroupConversation',
    defaultMessage:
      "Professeurs ou conseillers à l'emploi, pour préparer mon intervention auprès de groupes de collégiens, de lycéens ou de demandeurs d'emploi (en présentiel ou en visioconférence)",
  },
});

export const PhonePanelTranslations = defineMessages({
  title: {
    id: 'Profile.PhonePanelTranslations.title',
    defaultMessage: 'Indiquez vos coordonnées',
  },
  subtitle: {
    id: 'Profile.PhonePanelTranslations.subtitle',
    defaultMessage:
      'Entrez votre numéro de téléphone. Cette information sera uniquement visible par les personnes avec qui un rendez-vous est prévu.',
  },

  phone: {
    id: 'Profile.PhonePanelTranslations.phone',
    defaultMessage: 'Numéro de téléphone',
  },
  phoneDontShare: {
    id: 'Profile.PhonePanelTranslations.phoneDontShare',
    defaultMessage:
      'Ne pas communiquer mon numéro aux personnes rencontrées sauf si vous sélectionnez rencontre téléphonique lors d’une prise de RDV',
  },
});

export const MeetingPlacePanelTranslations = defineMessages({
  title: {
    id: 'Profile.MeetingPlacePanelTranslations.title',
    defaultMessage: 'Lieu de rencontres',
  },
  subtitle: {
    id: 'Profile.MeetingPlacePanelTranslations.subtitle',
    defaultMessage: 'Veuillez indiquer votre lieu de rencontres.',
  },

  address: {
    id: 'Profile.MeetingPlacePanelTranslations.address',
    defaultMessage: 'Adresse',
  },
});

export const LanguagesPanelTranslations = defineMessages({
  title: {
    id: 'Profile.LanguagesPanelTranslations.title',
    defaultMessage: 'Langues',
  },
  subtitle: {
    id: 'Profile.LanguagesPanelTranslations.subtitle',
    defaultMessage: "Choisissez les langues avec lesquels vous êtes assez à l'aise pour effectuer des entretiens.",
  },
  languages: {
    id: 'Profile.LanguagesPanelTranslations.languages',
    defaultMessage: 'Langues parlées',
  },
});

export const GalleryPanelTranslations = defineMessages({
  title: {
    id: 'Profile.GalleryPanelTranslations.title',
    defaultMessage: 'Galerie photo',
  },
  subtitle: {
    id: 'Profile.GalleryPanelTranslations.subtitle',
    defaultMessage:
      "N'hésitez pas à rajouter des photos qui pourraient illustrer votre profil, votre entreprise ou votre environnement de travail",
  },
});

export const OrientationPanelTranslations = defineMessages({
  title: {
    id: 'Profile.OrientationPanelTranslations.title',
    defaultMessage: 'Orientation',
  },
  subtitle: {
    id: 'Profile.OrientationPanelTranslations.subtitle',
    defaultMessage: "Ajoutez davantage d'informations sur votre métier pour aider les Membres à mieux se projeter.",
  },
  labelDiploma: {
    id: 'Profile.OrientationPanelTranslations.labelDiploma',
    defaultMessage: 'Quels niveaux d’études conseillez-vous pour exercer votre travail ?',
  },
  labelSkills: {
    id: 'Profile.OrientationPanelTranslations.labelSkills',
    defaultMessage: 'Quelles qualités sont requises pour exercer votre métier ?',
  },
});

export const ProfessorSituationTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.title',
    defaultMessage: 'Votre établissement et votre poste',
  },
  subtitle: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.subtitle',
    defaultMessage:
      'Renseignez l’établissement scolaire dans lequel vous travaillez actuellement ainsi que le rôle que vous occupez.',
  },
  secondarySituation: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.secondarySituation',
    defaultMessage: "Type d'établissement",
  },
  departmentLabel: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.departmentLabel',
    defaultMessage: "Département de l'établissement",
  },
  currentSchoolNameLabel: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.currentSchoolNameLabel',
    defaultMessage: 'Votre établissement',
  },
  currentSchoolNamePlaceholder: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.currentSchoolNamePlaceholder',
    defaultMessage: 'Entrez le nom ou la ville de votre établissement',
  },
  labelTeachedSchoolSubjects: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelCourse',
    defaultMessage: 'Matière enseignée',
  },
  labelProfession: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelProfession',
    defaultMessage: 'Votre métier',
  },
  accompaniedStudyLevels: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.studyLevelSupported',
    defaultMessage: "Niveaux d'études accompagnés",
  },
  labelPrincipalTeacher: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelPrincipalTeacher',
    defaultMessage: 'Etes-vous professeur principal ?',
  },
  labelOther: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelOther',
    defaultMessage: 'Précisez',
  },
  emptySchoolNameMessage: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.emptySchoolNameMessage',
    defaultMessage: "Pas de résultat. Cliquez ici si vous n'avez pas trouvé votre établissement dans la liste",
  },
});

export const FranceTravailSupportSituationTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.title',
    defaultMessage: 'La structure dans laquelle vous travaillez',
  },
  subtitle: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.subtitle',
    defaultMessage:
      'Renseignez le type de structure dans laquelle vous travaillez ainsi que le ou les programme(s) suivi(s) par les demandeurs d’emploi concernés par cette démarche.',
  },
  organizationTypeLabel: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.organizationTypeLabel',
    defaultMessage: 'Type de structure dans laquelle vous travaillez',
  },
  labelOther: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelOther',
    defaultMessage: 'Si autre, précisez',
  },
  labelJobTitle: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelJobTitle',
    defaultMessage: 'Poste occupé',
  },
  labelCompanyName: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelCompanyName',
    defaultMessage: 'Nom de l’agence ou de la structure dans laquelle vous travaillez',
  },
  placeholderCompanyName: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.placeholderCompanyName',
    defaultMessage: 'Exemple : Agence France Travail Paris 12ème Diderot',
  },
  labelPrograms: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelPrograms',
    defaultMessage: "Type d'accompagnement",
  },
});
