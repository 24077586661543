import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { useLanguage } from 'hooks/use-languages/useLanguages';
import { CommonTranslations } from 'i18n/common.translations';
import { LanguagesTranslations } from 'i18n/languages.translations';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Language } from '../../../@types/graphql';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import RemovableTag from '../../../components/removable-tag/RemovableTag';
import StaticAutocompleteMultiSelectInput from '../../../components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import './LanguagesPanel.scss';
import { LanguagesPanelTranslations } from './panels.translations';
import { languagesPanelValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';

type FormikValues = {
  languages: Language[];
};

export default function LanguagesPanel() {
  const { language } = useLanguage();

  const intl = useIntl();

  const { updateUserCompletion } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      languages: [language === 'en' ? Language.English : Language.French],
    },
    validationSchema: languagesPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ languages }) => {
      networkState.loading();
      await updateUser({ languages: languages });
      networkState.succeeded();
    },
  });

  const addLanguage = useCallback(
    (language: Language) => {
      if (!formik.values.languages.includes(language)) {
        formik.setFieldValue('languages', [...formik.values.languages, language]);
      }
    },
    [formik.values.languages, formik.setFieldValue],
  );

  const removeLanguage = useCallback(
    (language: Language) => {
      const newArray = formik.values.languages.filter((lang) => lang !== language);
      formik.setFieldValue('languages', newArray);
    },
    [formik.values.languages, formik.setFieldValue],
  );

  useEffect(() => {
    updateUserCompletion({ languages: formik.values.languages });
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <StaticAutocompleteMultiSelectInput
        dataCy="language"
        name="language"
        label={intl.formatMessage(LanguagesPanelTranslations.languages)}
        placeholder={intl.formatMessage(LanguagesPanelTranslations.placeholder)}
        values={Object.values(Language).map((value) => ({
          value,
          translation: intl.formatMessage(LanguagesTranslations[value]),
        }))}
        selectedValues={formik.values.languages}
        onChange={addLanguage}
        sort
      />

      <div className="languages-panel__languages">
        {formik.values.languages.map((lang) => (
          <RemovableTag
            key={lang}
            value={lang}
            translation={LanguagesTranslations[lang]}
            onRemove={removeLanguage}
          />
        ))}
      </div>

      {formik.errors.languages && formik.touched.languages && (
        <div className="languages-panel__error">{formik.errors.languages}</div>
      )}

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.isValid}
      />
    </form>
  );
}
