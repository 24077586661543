import { useMutation } from '@apollo/client';
import { REQUEST_MODERATION_MUTATION } from 'gqls/Publication.gql';
import { useLayoutEffect } from 'react';
import { PANELS } from '.';
import { RequestModerationMutation, RequestModerationMutationVariables } from '../../../@types/graphql';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { errorTracker } from 'error-tracker/error-tracker';
import useMarketplaceRedirect from 'hooks/use-marketplace-redirect';

export default function SubmitModerationPanel() {
  const { user } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const { currentPanelIndex } = useProfileCompletionContext();
  const { setParam } = useMarketplaceRedirect();

  const [requestModeration] = useMutation<RequestModerationMutation, RequestModerationMutationVariables>(
    REQUEST_MODERATION_MUTATION,
    {
      variables: {
        userId: user?.id || '',
      },
    },
  );

  async function finishCompletion() {
    if (
      (user?.currentUserAuthorizations?.canRequestModeration && user?.isModeratable) ||
      (user?.currentUserAuthorizations?.canRequestAdminRhModeration && user?.isAdminRhModeratable)
    ) {
      await requestModeration();
    } else {
      if (user?.wantToShare) {
        errorTracker.sendError(
          '[REQUEST_MODERATION] User cannot request moderation at the end of step by step V2',
          'User cannot request moderation at the end of step by step V2',
          {
            namespace: 'Frontend',
            tags: {
              namespace: 'Frontend',
              isModeratable: `${user?.isModeratable}`,
              isAdminHRModeratable: `${user?.isAdminRhModeratable}`,
              canRequestModeration: `${user?.currentUserAuthorizations?.canRequestModeration}`,
              canRequestAdminRhModeration: `${user?.currentUserAuthorizations?.canRequestAdminRhModeration}`,
            },
          },
        );
      }
    }
    await updateUser({ showCongratulationsPanel: false });
    if (user?.wantToShare) setParam('snackbar-intent', 'signed_up_as_ambassador');
  }

  useLayoutEffect(() => {
    // REFACTO: we should not need this condition to not trigger confettins every frames ...
    if (PANELS[currentPanelIndex].path.includes('submit-moderation')) finishCompletion();
  }, [PANELS, currentPanelIndex, user?.isModeratable, requestModeration]);

  return null;
}
