import { Dispatch, SetStateAction } from 'react';
import { useQuery } from '@apollo/client';
import { useLanguage } from 'hooks/use-languages/useLanguages';
import { PropsWithChildren, createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Routes from 'Routes';
import {
  AvailableLanguagesEnum,
  Get_User_CompletionQuery,
  Get_User_CompletionQueryVariables,
  ProfileCompletionUserPartsFragment,
  CompletionContextEnum,
} from '../../@types/graphql';
import { GET_USER_COMPLETION_QUERY } from './ProfileCompletion.gql';

type ProfileCompletionContextType = {
  currentPanelIndex: number;
  setCurrentPanelIndex: Dispatch<SetStateAction<number>>;

  completion: number;

  user?: Partial<ProfileCompletionUserPartsFragment>;

  updateUserCompletion: (result: Partial<ProfileCompletionUserPartsFragment>) => void;

  setCompletion: (value: number) => void;
};

const DEFAULT_VALUES: ProfileCompletionContextType = {
  currentPanelIndex: 0,
  setCurrentPanelIndex: () => {},

  completion: 0,

  updateUserCompletion: () => {},
  setCompletion: () => {},
};

export const ProfileCompletionContext = createContext<ProfileCompletionContextType>(DEFAULT_VALUES);

export function ProfileCompletionContextProvider({ children }: PropsWithChildren) {
  const language = useLanguage();
  const navigate = useNavigate();

  const [user, setUser] = useState<Partial<ProfileCompletionUserPartsFragment>>();
  const [currentPanelIndex, setCurrentPanelIndex] = useState(DEFAULT_VALUES.currentPanelIndex);
  const [completion, setCompletion] = useState(DEFAULT_VALUES.completion);
  const context = localStorage.getItem('completion_context');

  useQuery<Get_User_CompletionQuery, Get_User_CompletionQueryVariables>(GET_USER_COMPLETION_QUERY, {
    variables: {
      completionContext:
        context === 'contact' ? CompletionContextEnum.ContactAmbassador : CompletionContextEnum.StepByStep,
      locale: language.language === 'fr' ? AvailableLanguagesEnum.Fr : AvailableLanguagesEnum.En,
    },
    onCompleted: (data) => {
      if (!data.currentUserV2) {
        localStorage.removeItem('completion_context');
        localStorage.removeItem('company_registration_token');
        navigate(Routes.home);
        return;
      }
      setUser(data.currentUserV2);
    },
  });

  function updateUserCompletion(values: Partial<ProfileCompletionUserPartsFragment>) {
    setUser((u) => ({ ...u, ...values }));
  }

  return (
    <ProfileCompletionContext.Provider
      value={{
        currentPanelIndex,
        setCurrentPanelIndex,
        completion,
        user,
        updateUserCompletion,
        setCompletion,
      }}
    >
      {children}
    </ProfileCompletionContext.Provider>
  );
}
