import classNames from 'classnames';
import CheckIcon from 'assets/component-icons/CheckIcon';
import ChevronDownIcon from 'assets/component-icons/ChevronDownIcon';
import { IconProps } from 'assets/component-icons/Icon';
import React, { ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import { InputValue, sortByTranslation } from '../types/BaseInputValue';
import './SelectInput.scss';

type SelectInputProps<T> = {
  icon?: ComponentType<IconProps>;
  name: string;
  label?: string;
  value?: T;
  values: InputValue<T>[];
  initialValue?: InputValue<T> | null;
  placeholder?: string;
  onChange: (value: T) => void;
  error?: string;
  sort?: boolean;
  className?: string;
  disabled?: boolean;
  bigLabel?: boolean;
  dataCy?: string;

};

export default function SelectInput<T extends string>({
  icon: Icon,
  name,
  label,
  placeholder,
  error,
  value,
  values: paramValues,
  sort,
  onChange,
  className,
  disabled,
  bigLabel,
  dataCy,

}: SelectInputProps<T>) {
  const componentRef = useRef<HTMLDivElement>(null);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const parsedValue = useMemo(() => paramValues.find(({ value: v }) => v === value), [value, paramValues]);

  const values: InputValue<T>[] = useMemo(
    () => (sort ? sortByTranslation(paramValues) : paramValues),
    [sort, paramValues],
  );

  function handleComponentClick() {
    if (disabled) {
      return;
    }
    setDropdownOpen((prev) => {
      const mirrorValue = !prev;

      return mirrorValue;
    });
  }

  function handleSelection(value: T) {
    onChange(value);
    setDropdownOpen(false);
  }

  useEffect(() => {
    document.addEventListener('mousedown', (ev) => {
      if (componentRef.current && !componentRef.current.contains(ev.target as Node)) {
        setDropdownOpen(false);
      }
    });
  }, [isDropdownOpen, values, value]);

  return (
    <div ref={componentRef} className={bigLabel ? 'select-input--big-label' : 'select-input'}>
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error })}
        >
          {label}
        </label>
      )}

      <div
        data-cy={dataCy}
        className={classNames(
          'select-input__main',
          { 'select-input__main--focused': isDropdownOpen },
          { 'select-input__main--error': error },
          { 'select-input__main--disabled': disabled },
          className,
        )}
        onClick={handleComponentClick}
      >
        {Icon && <Icon className="select-input__main__icon" />}

        {parsedValue ? <div>{parsedValue.translation}</div> : <div className="placeholder">{placeholder}</div>}

        <label htmlFor={name}>
          <ChevronDownIcon
            className={classNames('select-input__main__icon', {
              'select-input__main__icon--turn': isDropdownOpen,
            })}
          />
        </label>
      </div>

      {isDropdownOpen && (
        <ul className="select-input__dropdown" data-cy={dataCy && `${dataCy}__dropdown`}>
          {(sort ? sortByTranslation(values) : values).map(({ value, translation }) => (
            <li
              key={value}
              className={classNames('select-input__dropdown__option', {
                'select-input__dropdown__option--selected': value === parsedValue?.value,
              })}
            >
              <button
                data-cy={dataCy && `${dataCy}__item`}
                type="button"
                onClick={() => handleSelection(value)}
              >
                <CheckIcon />
                {translation}
              </button>
            </li>
          ))}
        </ul>
      )}

      {error && <span className="select-input__error">{error}</span>}
    </div>
  );
}
