/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useLocation, Location } from 'react-router-dom';
import { Formik } from 'formik';
import Routes from '../../../Routes';
import RTFBold from '../../commons/rich-text-formatting/bold/RTFBold';
import { useRequestEmailAddressConfirmationEmail } from './MustConfirmEmail.hooks';
import translations from './MustConfirmEmail.translations';
import validation from './MustConfirmEmail.validation';

import styles from './MustConfirmEmail.module.scss';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { AuthenticationAside } from 'components/layout/asides/authentication/AuthenticationAside';

type LocationState = {
  email: string;
  tutorEmail?: boolean;
  retry?: boolean;
};

function MustConfirmEmail() {
  const intl = useIntl();
  const location = useLocation() as Location<LocationState>;
  const [sentStatus, setSentStatus] = useState<boolean>(false);

  const onSubmit = useRequestEmailAddressConfirmationEmail(setSentStatus);

  if (!location.state.email) {
    return <Navigate to={Routes.home} />;
  }

  return (
    <Layout aside={<AuthenticationAside />}>
      <LayoutHeader>
        <h1>{intl.formatMessage(location.state.tutorEmail ? translations.titleTutor : translations.title)}</h1>
        <p>
          {intl.formatMessage(location.state.tutorEmail ? translations.descriptionTutor : translations.description, {
            email: location.state.email,
            boldThis: RTFBold,
          })}
        </p>
      </LayoutHeader>
      <div className={styles.container}>
        <img src="https://assets.myjobglasses.com/account/letter.png" alt="" />
        <Formik
          initialValues={{
            email: location.state.email,
          }}
          validateOnMount
          validateOnChange
          validationSchema={validation}
          enableReinitialize
          onSubmit={onSubmit}
          >
          {({ handleSubmit }) => {
            return (
              <form
              className={styles.form}
              onSubmit={handleSubmit}
              >
                {sentStatus ? (
                  <p
                  data-testid="email-resend"
                  className={styles.emailSent}
                  >
                    {intl.formatMessage(translations.emailSent)}
                  </p>
                ) : (
                  <PrimaryButton
                  data-cy="submit-button"
                  label={intl.formatMessage(translations.resendEmail)}
                  submit
                  outlined={!location.state.retry}
                  />
                  )}
              </form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
}

export default MustConfirmEmail;
