import { useMemo } from 'react';
import './PasswordChecker.scss';
import CheckIcon from 'assets/component-icons/CheckIcon';
import { computePasswordStrengthPercentage } from '../strength-hints/StrengthHints.util';
import XIcon from 'assets/component-icons/XIcon';

type PasswordCheckerProps = {
  password: string;
};

export default function PasswordChecker({ password }: PasswordCheckerProps) {
  const progress = useMemo(() => computePasswordStrengthPercentage(password), [password]);

  return (
    <>
      {password.length > 0 && (
        <>
          {progress === 100 ? <CheckIcon className="check-icon" /> : <XIcon className='x-icon' />}
        </>
      )}
    </>
  );
}
