import { defineMessages } from 'react-intl';

export const UploadLogoInputTranslations = defineMessages({
  modalTitle: {
    id: 'UploadLogoInputTranslations.modalTitle',
    defaultMessage: 'Recadrer votre avatar',
  },
  modalSubtitle: {
    id: 'UploadLogoInputTranslations.modalSubtitle',
    defaultMessage: 'Recadrer votre photo pour centrer votre visage.',
  },
  chooseAvatarButton: {
    id: 'UploadLogoInputTranslations.chooseAvatarButton',
    defaultMessage: 'Charger une photo',
  },
  resetAvatarButton: {
    id: 'UploadLogoInputTranslations.resetAvatarButton',
    defaultMessage: 'Changer de photo',
  },
	invalidType: {
		id: 'UploadLogoInputTranslations.invalidType',
		defaultMessage: 'Type de fichier invalide.',
	},
	fileTooLarge: {
		id: 'UploadLogoInputTranslations.fileTooLarge',
		defaultMessage: 'Fichier trop volumineux.',
	},
	failedToGetLink: {
		id: 'UploadLogoInputTranslations.failedToGetLink',
		defaultMessage: 'Impossible de récupérer le lien de téléchargement.',
	},
	uploadFailed: {
		id: 'UploadLogoInputTranslations.uploadFailed',
		defaultMessage: 'Échec du téléchargement du logo.',
	},
	chooseButtom: {
		id: 'UploadLogoInputTranslations.chooseButtom',
		defaultMessage: 'Choisir une image',
	},
});
