import React from 'react';
import { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  primaryColor?: string;
  secondaryColor?: string;
};

export default function AvatarIcon(props: Props) {
  const { primaryColor, secondaryColor, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="none"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      {/* Background Circle */}
      <circle
        cx="50"
        cy="50"
        r="45"
        fill={secondaryColor || '#F1978B'}
      />

      {/* Mask to clip the body so it doesn’t go behind the background */}
      <mask id="avatar-mask">
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="white"
        />
      </mask>

      {/* Body (Now correctly clipped) */}
      <g mask="url(#avatar-mask)">
        <ellipse
          cx="50"
          cy="80"
          rx="32"
          ry="18"
          fill={primaryColor || '#8C1B15'}
        />
      </g>

      {/* Head (Unmasked, in front) */}
      <circle
        cx="50"
        cy="40"
        r="15"
        fill={primaryColor || '#8C1B15'}
      />
    </svg>
  );
}
