import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { WantToSharePanelTranslations } from './panels.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import './WantToSharePanel.scss';
import TextButton from 'components/text-button/TextButton';
import { CommonTranslations } from 'i18n/common.translations';
import { useLocation } from 'react-router-dom';
import useMarketplaceRedirect from 'hooks/use-marketplace-redirect';

type FormikValues = {
  wantToShare?: boolean;
};

export default function WantToSharePanel() {
  const intl = useIntl();
  const location = useLocation();
  const isWantToShareStudent = location.pathname.endsWith('want-to-share-student');

  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);
  const { setParam } = useMarketplaceRedirect();

  const formik = useFormik<FormikValues>({
    initialValues: { wantToShare: undefined },
    validateOnMount: true,
    onSubmit: async ({ wantToShare }) => {
      networkState.loading();
      await updateUser({ wantToShare });
      networkState.succeeded();
      if (wantToShare === false) {
        setParam('snackbar-intent', 'signed_up_as_member');
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ButtonsGroup>
        <TextButton
          label={intl.formatMessage(
            isWantToShareStudent
              ? WantToSharePanelTranslations.wantToShareStudentTitle
              : WantToSharePanelTranslations.wantToShareTitle,
          )}
          content={intl.formatMessage(
            isWantToShareStudent
              ? WantToSharePanelTranslations.wantToShareStudentContent
              : WantToSharePanelTranslations.wantToShareContent,
          )}
          onClick={() => {
            formik.setFieldValue('wantToShare', true);
          }}
          selected={formik.values.wantToShare === true}
        />
        <TextButton
          label={intl.formatMessage(
            isWantToShareStudent
              ? WantToSharePanelTranslations.wantToPassStudentTitle
              : WantToSharePanelTranslations.wantToPassTitle,
          )}
          content={intl.formatMessage(WantToSharePanelTranslations.wantToPassStudentContent)}
          onClick={() => {
            formik.setFieldValue('wantToShare', false);
          }}
          selected={formik.values.wantToShare === false}
        />
      </ButtonsGroup>
      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        onClick={() => {
          formik.submitForm();
        }}
        disabled={formik.values.wantToShare === undefined}
      />
    </form>
  );
}
