import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import { ComponentType, DetailedHTMLProps, useEffect, useState } from 'react';
import './NumberInput.scss';
import { motion } from 'motion/react';

// eslint-disable-next-line max-len
type DefaultInputProps = Pick<
  DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'value' | 'onFocus' | 'onBlur' | 'placeholder' | 'type'
>;
type NumberInputProps = DefaultInputProps & {
  icon?: ComponentType<IconProps>;
  name?: string;
  label?: string;
  min?: string;
  max?: string;
  step?: string;
  error?: string;
  disabled?: boolean;
  dataCy?: string;
  initialValue?: string;
  withCounter?: boolean;
};

export default function NumberInput({
  icon: Icon,
  name,
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  error,
  min,
  max,
  step = '1',
  disabled,
  dataCy,
  initialValue,
  withCounter,
}: NumberInputProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState<number>(
    value !== undefined ? Number(value) : initialValue !== undefined ? Number(initialValue) : 0,
  );
  function handleFocus(event: any) {
    setIsFocused(true);
    onFocus?.(event);
  }

  function handleBlur(event: any) {
    setIsFocused(false);
    onBlur?.(event);
  }

  useEffect(() => {
    if (value !== undefined && value !== currentValue) {
      setCurrentValue(Number(value));
    }
  }, [value]);

  function updateValue(newValue: number | string) {
    if (newValue === "") {
      setCurrentValue(0);
      onChange?.({ target: { name, value: "" } } as React.ChangeEvent<HTMLInputElement>);
      return;
    }
  
    const parsedValue = Number(newValue);
    if (isNaN(parsedValue)) return;
  
    if (min !== undefined && parsedValue < Number(min)) return;
    if (max !== undefined && parsedValue > Number(max)) return;
  
    setCurrentValue(parsedValue);
    onChange?.({ target: { name, value: parsedValue.toString() } } as React.ChangeEvent<HTMLInputElement>);
  }
  

  function increment() {
    const stepValue = Number(step);
    updateValue(currentValue + stepValue);
  }

  function decrement() {
    const stepValue = Number(step);
    updateValue(currentValue - stepValue);
  }

  return (
    <div className="text-input">
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error })}
        >
          {label}
        </label>
      )}

      <div
        className={classNames(
          'text-input__main',
          { 'text-input__main--focused': isFocused },
          { 'text-input__main--error': error },
          { 'text-input__main--disabled': disabled },
          { 'text-input__main--with-counter': withCounter },
        )}
      >
        {Icon && <Icon className="text-input__main__icon" />}
        <input
          data-cy={dataCy}
          id={name}
          type={withCounter ? 'text' : 'number'}
          min={min}
          max={max}
          step={step}
          value={withCounter ? currentValue : value}
          onChange={withCounter ? (e) => updateValue(e.target.value) : onChange}
          {...{
            name,
            placeholder,
            initialValue,
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
        />
        {withCounter && (
          <div className="text-input__main__counter-buttons">
            <motion.button
              type="button"
              onClick={increment}
              disabled={disabled}
              whileTap={{ scale: 0.9, backgroundColor: '#f5f5f5' }}
              transition={{ duration: 0.1 }}
            >
              <span>+</span>
            </motion.button>
            <motion.button
              type="button"
              onClick={decrement}
              disabled={disabled}
              whileTap={{ scale: 0.9, backgroundColor: '#f5f5f5' }}
              transition={{ duration: 0.1 }}
            >
              <span>-</span>
            </motion.button>
          </div>
        )}
      </div>

      {error && (
        <span
          data-cy={dataCy && `${dataCy}__error`}
          className="text-input__error"
        >
          {error}
        </span>
      )}
    </div>
  );
}
