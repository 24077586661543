import { useLocation } from 'react-router-dom';
import { Location } from 'history';
import { useContext } from 'react';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import Routes from 'Routes';

enum SearchParamsKeys {
  schoolName = 'school_name',
  schoolTown = 'school_town',
  schoolType = 'school_type',
  schoolZipCode = 'school_zip_code',
}

function getSearchParams(location: Location) {
  const searchParams = new URLSearchParams(location.search);

  const invitationToken = searchParams.get('invitationToken');
  const schoolName = searchParams.get(SearchParamsKeys.schoolName);
  const schoolTown = searchParams.get(SearchParamsKeys.schoolTown);
  const schoolType = searchParams.get(SearchParamsKeys.schoolType);
  const schoolZipCode = searchParams.get(SearchParamsKeys.schoolZipCode);

  return {
    invitationToken,
    schoolName,
    schoolTown,
    schoolType,
    schoolZipCode,
  };
}

export default function useOAuth() {
  const location = useLocation();
  const params = getSearchParams(location);

  const settingsContext = useContext(SettingsContext);

  return {
    signInWithGoogle: () => {
      if (!settingsContext?.settings?.googleOauthClientId) return;

      const clientId = settingsContext.settings.googleOauthClientId;
      const authUrl = 'http://accounts.google.com/o/oauth2/v2/auth';
      const scopes = encodeURIComponent('openid email');
      const redirectUri = encodeURIComponent(
        `${window.location.protocol}//${window.location.host}${Routes.oAuthGoogle}`
      );
      const responseType = 'id_token';
      const nonce = btoa(Math.random().toString()).substring(0, 16);
      const googleLoginUrl = `${authUrl}?client_id=${clientId}&response_type=${responseType}&scope=${scopes}&redirect_uri=${redirectUri}&nonce=${nonce}`;

      localStorage.setItem(nonce, JSON.stringify(params));
      window.location.href = googleLoginUrl;
    },
    signInWithLinkedIn: () => {
      if (settingsContext?.settings) {
        const { settings } = settingsContext;

        const url = new URL(settings.linkedinAuthorizationUrl);
        const redirectUri = new URL(url.searchParams.get('redirect_uri')!);
        const state = redirectUri.searchParams.get('state');
        localStorage.setItem(state!, JSON.stringify(params));
        window.location.href = settings.linkedinAuthorizationUrl;
      }
    },
    signInWithFranceTravail: () => {
      if (settingsContext?.settings) {
        const { settings } = settingsContext;

        const url = new URL(settings.franceTravailAuthorizationUrl);
        const state = url.searchParams.get('state');

        localStorage.setItem(state!, JSON.stringify(params));

        window.location.href = settings.franceTravailAuthorizationUrl;
      }
    },
  };
}
