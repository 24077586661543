import { gql } from '@apollo/client';

export const JOIN_PROGRAM_WITH_CODE_MUTATION = gql`
  mutation JoinProgramWithCode($programCode: String!) {
    joinProgram(programCode: $programCode) {
      id
    }
  }
`;

export const JOIN_PROGRAM_WITH_INVITATION_MUTATION = gql`
  mutation JoinProgramWithInvitation($invitationId: ID!) {
    acceptInvitation(invitationId: $invitationId)
  }
`;

export const CHECK_PROGRAM_CODE_QUERY = gql`
  query CheckProgramCode($participationCode: String!) {
    program(participationCode: $participationCode) {
      id
      name
    }
  }
`;
