import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  CheckProgramCodeQuery,
  CheckProgramCodeQueryVariables,
  JoinProgramWithCodeMutation,
  JoinProgramWithCodeMutationVariables,
  PrimarySituation,
  PrimarySituationEnum,
} from '../../../@types/graphql';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import SelectInput from '../../../components/select-input/SelectInput';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import TextInputWithChecker, {
  TextInputWithCheckerStatus,
} from 'components/text-input-with-checker/TextInputWithChecker';
import { PrimarySituationPanelTranslations } from './panels.translations';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CHECK_PROGRAM_CODE_QUERY, JOIN_PROGRAM_WITH_CODE_MUTATION } from 'gqls/ProgramAffiliation.gql';

const SITUATIONS_WITH_PROGRAM_CODE_ASKED = [PrimarySituationEnum.Student, PrimarySituationEnum.JobSeeker];

type FormikValues = {
  primarySituation?: PrimarySituationEnum;
  programCode?: string;
  checkStatus?: TextInputWithCheckerStatus;
};

export default function PrimarySituationPanel() {
  const intl = useIntl();

  const userUpdate = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const settingsContext = useContext(SettingsContext);
  const { user } = useProfileCompletionContext();

  const [programName, setProgramName] = useState<string>('');

  const situations: PrimarySituation[] = useMemo(() => {
    if (!settingsContext?.settings) {
      return [];
    }
    if (user?.isCoach) {
      return settingsContext.settings.primarySituations.filter(
        (situation) =>
          situation.key === PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport ||
          situation.key === PrimarySituationEnum.PostBacStudentSupport ||
          situation.key === PrimarySituationEnum.JobSeekerSupport,
      );
    }
    return settingsContext.settings.primarySituations;
  }, [settingsContext?.settings]);

  const [joinProgramWithCode] = useMutation<JoinProgramWithCodeMutation, JoinProgramWithCodeMutationVariables>(
    JOIN_PROGRAM_WITH_CODE_MUTATION,
  );

  const formik = useFormik<FormikValues>({
    initialValues: {
      primarySituation: undefined,
      programCode: undefined,
      checkStatus: undefined,
    },
    onSubmit: async ({ primarySituation, programCode, checkStatus }) => {
      networkState.loading();
      if (!primarySituation) {
        return;
      }

      if (programCode && checkStatus !== 'error') {
        joinProgramWithCode({ variables: { programCode } });
      }
      await userUpdate({primarySituation, showVocationPanel: false})
      networkState.succeeded();
    },
  });

  const [checkProgramCode, { loading: checkProgramCodeLoading }] = useLazyQuery<
    CheckProgramCodeQuery,
    CheckProgramCodeQueryVariables
  >(CHECK_PROGRAM_CODE_QUERY, {
    onCompleted: ({ program }) => {
      if (program?.id) {
        formik.setFieldValue('checkStatus', 'checked')
        setProgramName(program?.name || '');
      }
    },
    onError: () => {
      formik.setFieldValue('checkStatus', 'error');
    },
  });

  const isValid = useMemo(() => {
    return !!formik.values.primarySituation;
  }, [formik.values.primarySituation]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <SelectInput
        dataCy="primary-situation"
        name="primarySituation"
        values={(situations || []).map(({ key, name }) => ({
          value: key,
          translation: name,
        }))}
        label={intl.formatMessage(PrimarySituationPanelTranslations.label)}
        placeholder={intl.formatMessage(PrimarySituationPanelTranslations.placeholder)}
        value={formik.values.primarySituation}
        onChange={(value) => formik.setFieldValue('primarySituation', value)}
      />

      {SITUATIONS_WITH_PROGRAM_CODE_ASKED.includes(formik.values.primarySituation || PrimarySituationEnum.Other) && (
        <div>
        <TextInputWithChecker
          name="programCode"
          label={intl.formatMessage(PrimarySituationPanelTranslations.codeInputLabel)}
          value={formik.values.programCode}
          onChange={formik.handleChange}
          status={checkProgramCodeLoading ? 'loading' : formik.values.checkStatus}
          onCheck={async () =>
            await checkProgramCode({ variables: { participationCode: formik.values.programCode || '' } })
          }
          />
          {formik.values.checkStatus === 'checked' && 
          <p style={{color: '#009220', fontSize: '12px', marginLeft: '2px'}}>{intl.formatMessage(PrimarySituationPanelTranslations.codeInputValid, {
            programName: programName,
          })}</p>
          }
        </div>
      )}

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!isValid}
      />
    </form>
  );
}
