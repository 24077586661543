import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import BriefcaseIcon from 'assets/component-icons/BriefcaseIcon';
import { useIntl } from 'react-intl';
import {
  JobSeekerSupportOrganizationType,
  JobSeekerSupportOrganizationTypeKeyEnum,
  JobSeekerSupportProgram,
  JobSeekerSupportProgramKeyEnum,
  PrimarySituationEnum,
} from '../../../@types/graphql';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import SelectInput from '../../../components/select-input/SelectInput';
import { FranceTravailSupportSituationTranslations } from './panels.translations';
import { CommonTranslations } from 'i18n/common.translations';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import StaticAutocompleteMultiSelectInput from 'components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import AutocompleteAddableMultiSelectInput from 'components/autocomplete-addable-multi-select-input/AutocompleteAddableMultiSelectInput';
import RemovableTag from 'components/removable-tag/RemovableTag';
import TextInput from '../../../components/text-input/TextInput';
import formikError from 'components/formik-helper/formik.helper';
import { JobSeekerSupportProgramKeyEnumTranslations } from 'i18n/programs-ft.translations';
import { franceTravailSupportSituationPanelValidationSchema } from './panels.validation';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import { UserContext } from 'contexts/user/UserContext';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';

type FormikValues = {
  primarySituation: PrimarySituationEnum;
  position: string;
  companyName?: string;
  jobSeekerSupportPrograms: string[];
  jobSeekerSupportOrganizationType: string;
};

export default function FranceTravailSupportSituationPanel({
  primarySituationProps,
}: {
  primarySituationProps: PrimarySituationEnum;
}) {
  const intl = useIntl();
  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();
  const settingsContext = useContext(SettingsContext);
  const initialPrimarySituationRef = useRef(primarySituationProps);

  const networkState = useFormikNetworkState(ArrowRightIcon);

  const [customProgram, setCustomProgram] = useState<string | null>(null);
  const [multiSelectPlaceholder, setMultiSelectPlacholder] = useState<string>('');

  const [customOrganizationTitle, setCustomOrganizationTitle] = useState<string | null>(null);

  const organizationTypes: JobSeekerSupportOrganizationType[] = useMemo(
    () => settingsContext?.settings?.jobSeekerSupportOrganizationTypes || [],
    [settingsContext?.settings?.jobSeekerSupportOrganizationTypes],
  );
  const programs: JobSeekerSupportProgram[] = useMemo(
    () => settingsContext?.settings?.jobSeekerSupportPrograms || [],
    [settingsContext?.settings?.jobSeekerSupportPrograms],
  );

  const [initialValues, setInitialValues] = useState<FormikValues>({
    primarySituation: primarySituationProps,
    position: user?.position || '',
    companyName: user?.companyName || '',
    jobSeekerSupportPrograms: user?.jobSeekerSupportPrograms || [],
    jobSeekerSupportOrganizationType: user?.jobSeekerSupportOrganizationType || '',
  });

  useEffect(() => {
    if (primarySituationProps !== initialPrimarySituationRef.current) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        primarySituation: primarySituationProps,
      }));
    }
  }, [primarySituationProps]);

  const formik = useFormik<FormikValues>({
    initialValues: initialValues,
    validationSchema: franceTravailSupportSituationPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      networkState.loading();
      const response = await updateUser(values);
      networkState.succeeded();
    },
  });

  const addCustomProgram = useCallback(
    (jobSeekerSupportPrograms: string[]) => {
      formik.setFieldValue('jobSeekerSupportPrograms', jobSeekerSupportPrograms);
    },
    [formik.values.jobSeekerSupportPrograms],
  );

  const addProgram = useCallback(
    (program: JobSeekerSupportProgramKeyEnum) => {
      if (program === JobSeekerSupportProgramKeyEnum.Other) {
        setCustomProgram('');
        setMultiSelectPlacholder(
          intl.formatMessage(JobSeekerSupportProgramKeyEnumTranslations[JobSeekerSupportProgramKeyEnum.Other]),
        );
      } else {
        if (!formik.values.jobSeekerSupportPrograms.includes(program)) {
          formik.setFieldValue('jobSeekerSupportPrograms', [...formik.values.jobSeekerSupportPrograms, program]);
        }
        setCustomProgram(null);
        setMultiSelectPlacholder('');
      }
    },
    [formik.values.jobSeekerSupportPrograms],
  );

  const formatError = (touched: any, errors: any, fieldName: string): string | undefined => {
    const error = formikError(touched, errors, fieldName);
    if (typeof error === 'string') {
      return error;
    } else if (error && typeof error === 'object') {
      return JSON.stringify(error);
    }
  };

  return (
    <>
      <SelectInput
        name="jobSeekerSupportOrganizationType"
        label={intl.formatMessage(FranceTravailSupportSituationTranslations.organizationTypeLabel)}
        icon={BriefcaseIcon}
        values={organizationTypes.map(({ key, name }) => ({
          value: name,
          translation: name,
        }))}
        value={formik.values.jobSeekerSupportOrganizationType}
        onChange={(organizationType) => {
          if (organizationType === JobSeekerSupportOrganizationTypeKeyEnum.Other) {
            setCustomOrganizationTitle('');
          } else {
            setCustomOrganizationTitle(null);
            formik.setFieldValue('jobSeekerSupportOrganizationType', organizationType);
          }
        }}
        error={formatError(formik.touched, formik.errors, 'jobSeekerSupportOrganizationType')}
      />

      {customOrganizationTitle !== null && (
        <TextInput
          name="jobSeekerSupportOrganizationType"
          label={intl.formatMessage(FranceTravailSupportSituationTranslations.labelOther)}
          value={customOrganizationTitle}
          onChange={(e) => {
            setCustomOrganizationTitle(e.target.value);
            formik.setFieldValue('jobSeekerSupportOrganizationType', e.target.value);
          }}
          error={formatError(formik.touched, formik.errors, 'jobSeekerSupportOrganizationType')}
        />
      )}

      <TextInput
        name="position"
        value={formik.values.position}
        label={intl.formatMessage(FranceTravailSupportSituationTranslations.labelJobTitle)}
        onChange={(e) => {
          formik.setFieldValue('position', e.target.value);
        }}
        error={formatError(formik.touched, formik.errors, 'position')}
      />

      <TextInput
        name="companyName"
        value={formik.values.companyName}
        label={intl.formatMessage(FranceTravailSupportSituationTranslations.labelCompanyName)}
        placeholder={intl.formatMessage(FranceTravailSupportSituationTranslations.placeholderCompanyName)}
        onChange={(e) => {
          formik.setFieldValue('companyName', e.target.value);
        }}
        error={formatError(formik.touched, formik.errors, 'companyName')}
      />

      <StaticAutocompleteMultiSelectInput
        name="jobSeekerSupportPrograms"
        label={intl.formatMessage(FranceTravailSupportSituationTranslations.labelPrograms)}
        values={programs.map((value) => ({
          value: value.key,
          translation: value.name,
        }))}
        placeholder={multiSelectPlaceholder}
        selectedValues={formik.values.jobSeekerSupportPrograms || []}
        onChange={(value: string) => addProgram(value as JobSeekerSupportProgramKeyEnum)}
        error={formatError(formik.touched, formik.errors, 'jobSeekerSupportPrograms')}
      />

      {customProgram !== null && (
        <AutocompleteAddableMultiSelectInput
          name="jobSeekerSupportPrograms"
          label={intl.formatMessage(FranceTravailSupportSituationTranslations.labelOther)}
          values={formik.values.jobSeekerSupportPrograms}
          selectedValues={formik.values.jobSeekerSupportPrograms}
          onSelection={addCustomProgram}
          onTyping={setCustomProgram}
        />
      )}

      <div className="coach-panel__tags">
        {formik.values.jobSeekerSupportPrograms.map((program) => {
          const isCustomProgram = !Object.values(JobSeekerSupportProgramKeyEnum).includes(
            program as JobSeekerSupportProgramKeyEnum,
          );
          return (
            <RemovableTag
              key={program}
              value={program}
              name={program}
              translation={
                !isCustomProgram
                  ? JobSeekerSupportProgramKeyEnumTranslations[
                      program as keyof typeof JobSeekerSupportProgramKeyEnumTranslations
                    ]
                  : undefined
              }
              onRemove={() =>
                formik.setFieldValue(
                  'jobSeekerSupportPrograms',
                  formik.values.jobSeekerSupportPrograms.filter((v) => v !== program),
                )
              }
            />
          );
        })}
      </div>

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          onClick={formik.handleSubmit}
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.isValid}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => formik.resetForm()}
          iconButtonWithText
        />
      </footer>
    </>
  );
}
