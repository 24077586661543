import { PropsWithChildren, useState } from 'react';
import './Layout.scss';
import { motion, AnimatePresence } from 'motion/react';
import classNames from 'classnames';
import { Languages, useLanguage } from 'hooks/use-languages/useLanguages';
import { setStorageItem } from 'utils/storage';
import Storage from 'enums/storage';
import { AuthenticationTranslations } from 'scenes/authentication/Authentication.translations';
import { useIntl } from 'react-intl';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes from 'Routes';
import { Link } from 'react-router-dom';
import BrowserIcon from 'assets/component-icons/BrowserIcon';

type LayoutProps = PropsWithChildren & {
  aside?: React.ReactNode;
  withMobileAppPopup?: boolean;
  notAnimated?: boolean;
  withCompanySignUpBtn?: boolean;
  withFullScreenImage?: boolean;
};

export default function Layout({
  children,
  aside,
  withCompanySignUpBtn,
  withMobileAppPopup,
  withFullScreenImage,
}: LayoutProps) {
  const { language, setLanguage } = useLanguage();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [showMobileAppPopup, setShowMobileAppPopup] = useState(withMobileAppPopup);

  function changeLanguage(lang: Languages) {
    setStorageItem(Storage.LANGUAGE, lang);
    setLanguage(lang);
    window.location.reload();
  }

  const isOnHrAdminSignup = location.pathname === Routes.hrAdminSignup;
  const route = isOnHrAdminSignup ? Routes.signup : Routes.hrAdminSignup;
  const buttonLabel = isOnHrAdminSignup
    ? AuthenticationTranslations.userSignup
    : AuthenticationTranslations.companySignup;

  return (
    <div className={classNames('layout', { 'layout--full-screen-image': withFullScreenImage })}>
      <AnimatePresence>
        {showMobileAppPopup && (
          <>
            <motion.div
              className="layout__overlay"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            />
            <motion.div
              className="layout__mobile-app-popup"
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{ duration: 0.4, ease: 'easeInOut' }}
            >
              <h1>{intl.formatMessage(AuthenticationTranslations.appPopupTitle)}</h1>
              <div className="layout__mobile-app-popup__app-cta">
                <div className="layout__mobile-app-popup__app-cta__icon">
                  <img
                    src="https://assets.myjobglasses.com/logo/mjg-logo-v3-compass.svg"
                    alt="my job glasses logo"
                  />
                </div>
                <p>My Job Glasses</p>
                <Link
                  className="layout__mobile-app-popup__app-cta__button"
                  to="https://myjobglasses.onelink.me/MUDH/8xapuqxn"
                >
                  {intl.formatMessage(AuthenticationTranslations.open)}
                </Link>
              </div>
              <div className="layout__mobile-app-popup__browser-cta">
                <div className="layout__mobile-app-popup__browser-cta__icon">
                  <BrowserIcon />
                </div>
                <p>{intl.formatMessage(AuthenticationTranslations.stayOnBrowser)}</p>
                <button
                  className="layout__mobile-app-popup__browser-cta__button"
                  onClick={() => setShowMobileAppPopup(false)}
                >
                  {intl.formatMessage(AuthenticationTranslations.signIn)}
                </button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
      <div className="layout__main">
        <main
          className={classNames('layout__main__container', {
            'layout__main__container--with-aside': aside && !withFullScreenImage,
          })}
        >
          <nav
            className={classNames('layout__main__container__nav', {
              'layout__main__container__nav--white': withFullScreenImage,
            })}
          >
            <img
              className="layout__main__container__nav__logo"
              src="https://assets.myjobglasses.com/logo/mjg-logo-v3-rectangle.svg"
              alt="my job glasses logo"
            />
            {!withFullScreenImage && (
              // Waiting for the English version of Connect
              <div className="layout__main__container__nav__languages">
                <button
                  type="button"
                  className={classNames({ selected: language === 'fr' })}
                  onClick={() => changeLanguage('fr')}
                >
                  FR
                </button>
                |
                <button
                  type="button"
                  className={classNames({ selected: language === 'en' })}
                  onClick={() => changeLanguage('en')}
                >
                  EN
                </button>
              </div>
            )}
            {withCompanySignUpBtn && (
              <PrimaryButton
                label={intl.formatMessage(buttonLabel)}
                cyLabel="company-signup"
                onClick={() => navigate(route)}
                outlined
                smaller
              />
            )}
          </nav>

          <div className="layout__main__container__separator" />

          <div className="layout__main__container__content">{children}</div>
        </main>
      </div>
      {aside && !withFullScreenImage && <aside className="layout__aside">{aside}</aside>}
    </div>
  );
}

export function LayoutHeader({ children }: PropsWithChildren) {
  return <div className="layout-header">{children}</div>;
}

export function LayoutSeparator({ children }: PropsWithChildren) {
  return <div className="layout-separator">{children}</div>;
}
