import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import { useIntl } from 'react-intl';
import PhoneIcon from 'assets/component-icons/PhoneIcon';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { contactPanelValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import PhoneInput from '../../../components/phone-input/PhoneInput';
import { ContactPanelTranslations } from './panels.translations';
import formikError from '../../../components/formik-helper/formik.helper';
import { CommonTranslations } from 'i18n/common.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import GooglePlaceInput from 'components/google-place-input/GooglePlaceInput';
import MarkerPinIcon from 'assets/component-icons/MarkerPinIcon';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';

type FormikValues = {
  phoneNumber: string;
  googlePlace?: {
    googlePlaceId: string;
    meetingPlace: string;
  };
};

export default function ContactPanel() {
  const intl = useIntl();

  const { updateUserCompletion } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      phoneNumber: '',
      googlePlace: undefined,
    },
    validationSchema: contactPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ phoneNumber, googlePlace }) => {
      networkState.loading();
      if (!googlePlace) return;
      const { googlePlaceId, meetingPlace } = googlePlace;

      await updateUser({ normalizedPhone: phoneNumber, googlePlaceId, meetingPlace });
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <GooglePlaceInput
        name="googlePlace"
        icon={MarkerPinIcon}
        label={intl.formatMessage(ContactPanelTranslations.location)}
        placeholder={intl.formatMessage(ContactPanelTranslations.locationPlaceholder)}
        onChange={(e) => {
          formik.setFieldValue('googlePlace', {
            googlePlaceId: e.place_id,
            meetingPlace: e.description,
          });
          updateUserCompletion({ meetingPlace: e.description });
        }}
        error={formikError(formik.touched, formik.errors, 'googlePlace')}
      />
      <PhoneInput
        icon={PhoneIcon}
        name="phoneNumber"
        label={intl.formatMessage(ContactPanelTranslations.phone)}
        value={formik.values.phoneNumber}
        onChange={(phoneNumber) => {
          formik.setFieldValue('phoneNumber', phoneNumber);
        }}
        error={formikError(formik.touched, formik.errors, 'phoneNumber')}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        onClick={formik.handleSubmit}
        disabled={!formik.dirty}
      />
    </form>
  );
}
