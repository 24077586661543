import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import TextAreaInput from 'components/text-area-input/TextAreaInput';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import { memberPresentationValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { MemberPresentationPanelTranslations } from './panels.translations';
import { useEffect } from 'react';

type FormikValues = {
  memberPresentation: string;
};

export default function MemberPresentationPanel() {
  const intl = useIntl();

  const { user, updateUserCompletion } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      memberPresentation: '',
    },
    validationSchema: memberPresentationValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      networkState.loading();
      await updateUser(values);
      networkState.succeeded();
    },
  });

  useEffect(() => {
    updateUserCompletion({ memberPresentation: formik.values.memberPresentation });
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextAreaInput
        dataCy="member-presentation"
        name="memberPresentation"
        label={user?.presentationTranslations?.memberPresentationQuestion}
        value={formik.values.memberPresentation}
        onChange={formik.handleChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.stopPropagation(); // Prevents the form from capturing the "Enter" key, allowing the textarea to handle the line break
          }
        }}
        info={user?.presentationTranslations?.memberPresentationExample}
        placeholder={intl.formatMessage(MemberPresentationPanelTranslations.placeholder)}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.isValid}
      />
    </form>
  );
}
