import { Language } from '../@types/graphql';

export const LanguageToCountryFlag: Record<Language, string> = {
  [Language.Afrikaans]: '🇿🇦', // South Africa
  [Language.Albanian]: '🇦🇱',
  [Language.Arabic]: '🇸🇦', // Saudi Arabia (Generic for Arabic)
  [Language.Armenian]: '🇦🇲',
  [Language.Azeri]: '🇦🇿',
  [Language.Basque]: '🇪🇸', // Spain (Basque region)
  [Language.Belarusian]: '🇧🇾',
  [Language.Bengali]: '🇧🇩',
  [Language.Bosnian]: '🇧🇦',
  [Language.Bulgarian]: '🇧🇬',
  [Language.Burmese]: '🇲🇲',
  [Language.Catalan]: '🇪🇸', // Spain (Catalonia)
  [Language.Chinese]: '🇨🇳',
  [Language.Croatian]: '🇭🇷',
  [Language.Czech]: '🇨🇿',
  [Language.Danish]: '🇩🇰',
  [Language.Dutch]: '🇳🇱',
  [Language.English]: '🇬🇧', // United Kingdom (Common English representation)
  [Language.Estonian]: '🇪🇪',
  [Language.Filipino]: '🇵🇭',
  [Language.Finnish]: '🇫🇮',
  [Language.French]: '🇫🇷',
  [Language.Galician]: '🇪🇸', // Spain (Galicia)
  [Language.Georgian]: '🇬🇪',
  [Language.German]: '🇩🇪',
  [Language.Greek]: '🇬🇷',
  [Language.Guarani]: '🇵🇾',
  [Language.Gujarati]: '🇮🇳',
  [Language.Hebrew]: '🇮🇱',
  [Language.Hindi]: '🇮🇳',
  [Language.Hungarian]: '🇭🇺',
  [Language.Icelandic]: '🇮🇸',
  [Language.Indonesian]: '🇮🇩',
  [Language.Italian]: '🇮🇹',
  [Language.Japanese]: '🇯🇵',
  [Language.Javanese]: '🇮🇩', // Indonesia (Java island)
  [Language.Kannada]: '🇮🇳',
  [Language.Kazakh]: '🇰🇿',
  [Language.Khmer]: '🇰🇭',
  [Language.Kinyarwanda]: '🇷🇼',
  [Language.Korean]: '🇰🇷',
  [Language.Kurdish]: '🇮🇶', // Iraq (Kurdish Region)
  [Language.Latvian]: '🇱🇻',
  [Language.Lithuanian]: '🇱🇹',
  [Language.Macedonian]: '🇲🇰',
  [Language.Malay]: '🇲🇾',
  [Language.Malayalam]: '🇮🇳',
  [Language.Marathi]: '🇮🇳',
  [Language.Mongolian]: '🇲🇳',
  [Language.Nepalese]: '🇳🇵',
  [Language.Norwegian]: '🇳🇴',
  [Language.Oriya]: '🇮🇳',
  [Language.Pashto]: '🇦🇫', // Afghanistan
  [Language.Persian]: '🇮🇷',
  [Language.Polish]: '🇵🇱',
  [Language.Portuguese]: '🇵🇹',
  [Language.Punjabi]: '🇮🇳',
  [Language.Romanian]: '🇷🇴',
  [Language.Russian]: '🇷🇺',
  [Language.Serbian]: '🇷🇸',
  [Language.Sinhalese]: '🇱🇰',
  [Language.Slovak]: '🇸🇰',
  [Language.Slovenian]: '🇸🇮',
  [Language.Spanish]: '🇪🇸',
  [Language.Swahili]: '🇹🇿', // Tanzania (or KE for Kenya)
  [Language.Swedish]: '🇸🇪',
  [Language.Tajik]: '🇹🇯',
  [Language.Tamazight]: '🇲🇦', // Morocco (Tamazight)
  [Language.Tamil]: '🇮🇳',
  [Language.Thai]: '🇹🇭',
  [Language.Turkish]: '🇹🇷',
  [Language.Ukrainian]: '🇺🇦',
  [Language.Urdu]: '🇵🇰',
  [Language.Uzbek]: '🇺🇿',
  [Language.Vietnamese]: '🇻🇳',
  [Language.Visayan]: '🇵🇭', // Philippines
  [Language.Welsh]: '🏴󠁧󠁢󠁷󠁬󠁳󠁿', // Wales (UK)
};
