import React, { useMemo } from 'react';
import './AmbassadorView.scss';
import { PrimarySituationEnum, ProfileCompletionUserPartsFragment } from '../../../../../../@types/graphql';
import StarFatBorderIcon from 'assets/component-icons/StarFatBorderIcon';
import { defineMessages, useIntl } from 'react-intl';
import { GradeEnumTranslations } from 'i18n/grade.translations';
import MarkerPinIcon from 'assets/component-icons/MarkerPinIcon';
import UserIcon from 'assets/component-icons/UserIcon';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import { LanguageToCountryFlag } from 'constants/languages.constant';

const TRANSLATIONS = defineMessages({
  presentation: {
    id: 'AmbassadorView.presentation',
    defaultMessage: 'Présentation',
  },
});

type Props = {
  user: Partial<ProfileCompletionUserPartsFragment>;
};

export const AmbassadorView: React.FC<Props> = ({ user }: Props) => {
  const { formatMessage } = useIntl();
  const {
    firstName,
    lastName,
    primarySituation,
    companyName,
    meetingPlace,
    avatars,
    currentSchoolName,
    ambassadorPresentation,
    interestsPresentation,
    aspirationPresentation,
    expertisePresentation,
    isPremiumAmbassador,
    profileBannerDesktop,
    languages,
  } = user;

  const isStudent = useMemo(() => primarySituation?.key === PrimarySituationEnum.Student, [primarySituation]);

  return (
    <div className="ambassador-view">
      <div className="ambassador-view__identity">
        <header
          className={classNames('ambassador-view__identity__banner', {
            'ambassador-view__identity__banner--premium': isPremiumAmbassador && !profileBannerDesktop?.url,
            'ambassador-view__identity__banner--custom': profileBannerDesktop?.url,
          })}
        >
          {profileBannerDesktop?.url && (
            <img
              className="ambassador-view__identity__banner--custom__image"
              src={profileBannerDesktop.url}
            />
          )}
          <img
            className="ambassador-view__identity__banner__avatar"
            src={avatars?.url || ''}
          />
        </header>

        <div className="ambassador-view__identity__content">
          <div className="ambassador-view__identity__content__first-row">
            <div className="ambassador-view__identity__content__first-row__name">
              {firstName} {lastName} {languages?.map((l) => LanguageToCountryFlag[l])}
            </div>
          </div>
          <div className="ambassador-view__identity__content__position">
            {getComposedPosition(isStudent, user, formatMessage)}
          </div>
          <div className="ambassador-view__identity__content__company-name">
            {isStudent ? currentSchoolName : companyName}
          </div>
          <div className="ambassador-view__identity__content__location">
            <MarkerPinIcon /> {meetingPlace}
          </div>
        </div>
      </div>

      <div className="ambassador-view__presentation">
        <div className="ambassador-view__presentation__title">
          <UserIcon /> {formatMessage(TRANSLATIONS.presentation)}
        </div>

        <div className="ambassador-view__presentation__content">
          {ambassadorPresentation ? (
            <div>{splitParagraphs(ambassadorPresentation)}</div>
          ) : (
            <Skeleton
              count={3}
              height={16}
            />
          )}
          {interestsPresentation ? (
            <div>{splitParagraphs(interestsPresentation)}</div>
          ) : (
            <Skeleton
              count={3}
              height={16}
            />
          )}
          {aspirationPresentation ? (
            <div>{splitParagraphs(aspirationPresentation)}</div>
          ) : (
            <Skeleton
              count={3}
              height={16}
            />
          )}
          {expertisePresentation ? (
            <div>{splitParagraphs(expertisePresentation)}</div>
          ) : (
            <Skeleton
              count={3}
              height={16}
            />
          )}
        </div>
      </div>
    </div>
  );
};

function getComposedPosition(
  isStudent: boolean,
  user: Partial<ProfileCompletionUserPartsFragment>,
  formatMessage: any,
) {
  let position: string | null | undefined = '';
  if (isStudent) {
    if (user.currentGrade) position += formatMessage(GradeEnumTranslations[user.currentGrade]);
    if (user.preparedDiplomaTitle) position += ' - ' + user.preparedDiplomaTitle; // Add prepared di
  } else position = user.jobTitle;
  return position;
}

function splitParagraphs(text: string) {
  return text.split('\n').map((p, i) => <p key={i}>{p}</p>);
}
