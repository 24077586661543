import CameraPlusIcon from 'assets/component-icons/CameraPlusIcon';
import classNames from 'classnames';
import useModal from 'components/modal/Modal.hook';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import { ChangeEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import './AvatarInput.scss';
import { AvatarInputTranslations } from './AvatarInput.translations';
import AvatarCropModal from './crop-modal/AvatarCropModal';

const ACCEPTED_IMAGE_TYPES = ['image/png', 'image/jpg', 'image/jpeg', 'image/heic'];

type AvatarInputProps = {
  initialImage?: File;
  onChange: (avatar: Blob) => void;
  label?: string;
  small?: boolean;
};

export default function AvatarInput({ initialImage, onChange, small, label }: AvatarInputProps) {
  const { formatMessage } = useIntl();

  const [openModal, closeModal] = useModal();

  const inputRef = useRef<HTMLInputElement>(null);

  const [blob, setBlob] = useState<string>();
  const [image, setImage] = useState<File>();

  useEffect(() => {
    if (initialImage) setImage(initialImage);
  }, [initialImage]);

  useLayoutEffect(() => {
    if (!blob || image) return;
    openModal({
      title: formatMessage(AvatarInputTranslations.modalTitle),
      subtitle: formatMessage(AvatarInputTranslations.modalSubtitle),
      content: (
        <AvatarCropModal
          value={blob}
          onCropComplete={handleImageCrop}
        />
      ),
    });
  }, [blob, openModal]);

  const onImageUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target?.files || !event.target.files[0]) return;
      setBlob(URL.createObjectURL(event.target.files[0]));
      event.target.value = '';
    },
    [setBlob],
  );

  const onImageReset = useCallback(() => {
    setBlob(undefined);
    setImage(undefined);
    onUploadClick();
  }, [setBlob, setImage, onUploadClick]);

  function handleImageCrop(avatar: File) {
    setImage(avatar);
    onChange(avatar);
    closeModal();
  }

  function onUploadClick() {
    inputRef.current?.click();
  }

  return (
    <>
      {label && <label className="label" htmlFor="avatar">{label}</label>}
      <div className={classNames('avatar-input', { 'avatar-input--small': small })}>
        <input
          name="avatar"
          data-cy="avatar-input"
          className="avatar-crop-modal__file-input"
          ref={inputRef}
          type="file"
          accept={ACCEPTED_IMAGE_TYPES.join(', ')}
          onChange={onImageUpload}
        />

        <button
          type="button"
          className="avatar-input__viewer"
          onClick={onUploadClick}
          disabled={!!image}
        >
          {image ? (
            <img
              className="avatar-input__viewer__avatar"
              src={URL.createObjectURL(image)}
            />
          ) : (
            <CameraPlusIcon className={classNames('avatar-input__viewer__placeholder-icon', { 'avatar-input__viewer__placeholder-icon--small': small })}/>
          )}
        </button>

        <SecondaryButton
          label={formatMessage(
            !image ? AvatarInputTranslations.chooseAvatarButton : AvatarInputTranslations.resetAvatarButton,
          )}
          onClick={image ? onImageReset : onUploadClick}
          smaller
        />
      </div>
    </>
  );
}
