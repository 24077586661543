import { useIntl } from 'react-intl';
import IconGoogleConnect from './icon-google-connect.svg';
import { AuthenticationTranslations } from 'scenes/authentication/Authentication.translations';
import styles from './GoogleButton.module.scss';

export default function GoogleButton(props: JSX.IntrinsicElements['button']) {
  const intl = useIntl();

  return (
    <button
      className={styles.GoogleButton}
      type="button"
      { ...props }
    >
      <img
        src={IconGoogleConnect}
        alt="Ic&ocirc;ne Google"
        width="25"
        height="31"
      />
      <span
        data-testid="Google-button-text"
        className={styles.text}
      >
        {intl.formatMessage(AuthenticationTranslations.google)}
      </span>
    </button>
  );
}
