import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import './AuthenticationAside.scss';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import { AnimatePresence, motion } from 'motion/react';

const IMAGES_COUNT = 3;
const INTERVAL_DELAY = 4000;

const TRANSLATIONS = defineMessages<string>({
  title1: {
    id: 'AuthenticationAside.title1',
    defaultMessage: 'Des milliers de professionels disponibles',
  },
  subtitle1: {
    id: 'AuthenticationAside.subtitle1',
    defaultMessage: 'pour échanger avec vous',
  },
  title2: {
    id: 'AuthenticationAside.title2',
    defaultMessage: 'Partagez votre métier avec notre communauté',
  },
  subtitle2: {
    id: 'AuthenticationAside.subtitle2',
    defaultMessage: 'pour créer des vocations',
  },
  title3: {
    id: 'AuthenticationAside.title3',
    defaultMessage: 'Inscrivez-vous et échangez facilement',
  },
  subtitle3: {
    id: 'AuthenticationAside.subtitle3',
    defaultMessage: 'sur notre plateforme web ou notre application mobile',
  },
  download: {
    id: 'AuthenticationAside.download',
    defaultMessage: 'Téléchargez notre application',
  },
});

export const useAuthenticationAsideCarousel = () => {
  const [image, setImage] = useState(0);
  const interval = useRef<NodeJS.Timer | null>(null);

  const nextImage = useCallback(() => {
    setImage((_image) => (_image + 1) % IMAGES_COUNT);
  }, [image, IMAGES_COUNT]);

  const handleSelectorClick = useCallback(
    (i: number) => {
      setImage(i);
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = setInterval(nextImage, INTERVAL_DELAY);
      }
    },
    [interval, image],
  );

  useEffect(() => {
    if (!interval.current) interval.current = setInterval(nextImage, INTERVAL_DELAY);
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, []);

  return { image, handleSelectorClick };
};

export const AuthenticationAside: React.FC = memo(() => {
  const { formatMessage } = useIntl();
  const { image, handleSelectorClick } = useAuthenticationAsideCarousel();

  return (
    <div className="authentication-aside">
      <div className="authentication-aside__container">
        <div className="authentication-aside__container__carousel">
          <AnimatePresence mode="popLayout">
            <motion.img
              key={image}
              src={`https://assets.myjobglasses.com/account/auth-carousel-${image + 1}.png`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          </AnimatePresence>
        </div>

        <AnimatePresence mode="popLayout">
          <motion.p
            key={image}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="authentication-aside__container__title"
          >
            {formatMessage(TRANSLATIONS[`title${image + 1}`])}
          </motion.p>
        </AnimatePresence>
        <AnimatePresence mode="popLayout">
          <motion.p
            key={image}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="authentication-aside__container__subtitle"
          >
            {formatMessage(TRANSLATIONS[`subtitle${image + 1}`])}
          </motion.p>
        </AnimatePresence>

        <div className="authentication-aside__container__dots">
          {Array(IMAGES_COUNT)
            .fill(null)
            .map((_v, i) => (
              <div
                key={i}
                className={classNames({ active: i === image })}
                onClick={() => handleSelectorClick(i)}
              />
            ))}
        </div>
      </div>

      <footer className="authentication-aside__footer">
        <img
          src="https://assets.myjobglasses.com/account/download-app-qrcode.png"
          id="qrcode"
        />
        <p id="download">{formatMessage(TRANSLATIONS.download)}</p>
        <a
          href="https://apps.apple.com/fr/app/my-job-glasses/id6480446555"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://assets.myjobglasses.com/account/download-app-ios.png"
            id="ios"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.anthonypoyesis.myjobglasses&hl=fr&pli=1"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://assets.myjobglasses.com/account/download-app-android.png"
            id="android"
          />
        </a>
      </footer>
    </div>
  );
});
