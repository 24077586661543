import { AnimatePresence, motion } from 'motion/react';
import { useIntl } from 'react-intl';
import { Route, Routes as RouterRoutes, useLocation } from 'react-router-dom';
import { ProfileCompletionContextProvider } from './ProfileCompletionContext';
import ProfileCompletionLayout from './ProfileCompletionLayout';
import { PANELS } from './panels';

export default function ProfileCompletion() {
  const location = useLocation();
  const intl = useIntl();

  // improve animation by putting title and subtitle in the panels components
  return (
    <ProfileCompletionContextProvider>
      <ProfileCompletionLayout>
        <AnimatePresence mode="wait">
          <RouterRoutes
            location={location}
            key={location.key}
          >
            {PANELS.map((panel) => (
              <Route
                key={panel.path}
                path={panel.path}
                element={
                  <div>
                    {panel.title && (
                      <header>
                        <motion.h1
                          initial={{
                            opacity: 0,
                            transform: 'translate3d(20px, 0px, 0px)',
                          }}
                          animate={{
                            opacity: 1,
                            transform: 'translate3d(0px, 0px, 0px)',
                          }}
                          exit={{
                            opacity: 0,
                            transform: 'translate3d(-20px, 0px, 0px)',
                          }}
                          transition={{ duration: 0.25, ease: 'easeInOut' }}
                        >
                          {intl.formatMessage(panel.title)}
                        </motion.h1>
                        {panel.subtitle && (
                          <motion.p
                            initial={{
                              opacity: 0,
                              transform: 'translate3d(20px, 0px, 0px)',
                            }}
                            animate={{
                              opacity: 1,
                              transform: 'translate3d(0px, 0px, 0px)',
                            }}
                            exit={{
                              opacity: 0,
                              transform: 'translate3d(-20px, 0px, 0px)',
                            }}
                            transition={{ duration: 0.5, ease: 'backInOut' }}
                            dangerouslySetInnerHTML={{
                              __html: intl.formatMessage(panel.subtitle),
                            }}
                          />
                        )}
                      </header>
                    )}
                    <motion.div
                      initial={{
                        opacity: 0,
                        transform: 'translate3d(20px, 0px, 0px)',
                      }}
                      animate={{
                        opacity: 1,
                        transform: 'translate3d(0px, 0px, 0px)',
                      }}
                      exit={{
                        opacity: 0,
                        transform: 'translate3d(-20px, 0px, 0px)',
                      }}
                      transition={{ duration: 0.75, ease: 'backInOut' }}
                    >
                      {panel.component}
                    </motion.div>
                  </div>
                }
              />
            ))}
          </RouterRoutes>
        </AnimatePresence>
      </ProfileCompletionLayout>
    </ProfileCompletionContextProvider>
  );
}
