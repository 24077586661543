import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes from 'Routes';
import {
  OpenidConnectMutation,
  OpenidConnectMutationVariables,
  OpenidConnectProvider,
} from '../../../@types/graphql';
import { OPENID_MUTATION } from '../gql/OpenID.gql';

export default function GoogleOAuthCallback() {
  const location = useLocation();
  const navigate = useNavigate();

  const hashParams = new URLSearchParams(location.hash.substring(1));
  const idToken = hashParams.get('id_token')

  const [openidConnectMutation] = useMutation<OpenidConnectMutation, OpenidConnectMutationVariables>(OPENID_MUTATION, {
    onCompleted: async ({ openidConnect }) => {
      if (!openidConnect) { return }

      const { email, firstName, lastName, nonce } = openidConnect;

      if (openidConnect.accessToken) {
        navigate(Routes.home);
        window.location.reload();
      } else {
        localStorage.setItem(email, JSON.stringify({ firstName, lastName }));
        navigate(Routes.confirmSignup, { state: { email, firstName, lastName, nonce } });
      }
    },
  });

  useEffect(() => {
    if (!idToken) { return }

    openidConnectMutation({
      variables: {
        identityToken: idToken,
        provider: OpenidConnectProvider.Google,
      }
    })
  }, [])

  return (
    <Layout>
      <LayoutHeader>
        <h1>Nous interrogeons les services de Google ...</h1>
      </LayoutHeader>
    </Layout>
  );
}
