const MARKETPLACE_FRONTEND_URL = window.__RUNTIME_CONFIG__.REACT_APP_APP_FRONTEND_URL || 'http://localhost:4001';
const MARKETPLACE_REDIRECT_URL_SEARCH_PARAM = 'marketplace_redirect_url';
const MARKETPLACE_REDIRECT_URL_LS_KEY = 'marketplace_redirect_url';

function saveMarketplaceRedirection() {
  const url = new URL(window.location.href);
  const marketplaceRedirectURL = url.searchParams.get(MARKETPLACE_REDIRECT_URL_SEARCH_PARAM);
  if (marketplaceRedirectURL) {
    localStorage.setItem(MARKETPLACE_REDIRECT_URL_LS_KEY, marketplaceRedirectURL);
  }
}

function setParam(key: string, value: string) {
  const url = new URL(getMarketplaceRedirectionUrl() || MARKETPLACE_FRONTEND_URL);
  url.searchParams.set(key, value);
  localStorage.setItem(MARKETPLACE_REDIRECT_URL_LS_KEY, url.toString());
}

function getMarketplaceRedirectionUrl(): string | null | undefined {
  return localStorage.getItem(MARKETPLACE_REDIRECT_URL_LS_KEY);
}

function redirectToMarketplace() {
  const marketplaceRedirectURL = localStorage.getItem(MARKETPLACE_REDIRECT_URL_LS_KEY);
  if (marketplaceRedirectURL) {
    localStorage.removeItem(MARKETPLACE_REDIRECT_URL_LS_KEY);
    return marketplaceRedirectURL;
  }
  return MARKETPLACE_FRONTEND_URL;
}

export default function useMarketplaceRedirect() {
  return {
    setRedirectIfNeeded: () => saveMarketplaceRedirection(),
    getRedirectUrl: () => redirectToMarketplace(),
    setParam,
  };
}
