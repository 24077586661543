import { AnimatePresence, motion } from 'motion/react';
import React, { memo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import classNames from 'classnames';

import './ExpandableCompletionBlock.scss';
import useIsMobile from 'hooks/useIsMobile';
import CheckIcon from 'assets/component-icons/CheckIcon';
import ChevronDownIcon from 'assets/component-icons/ChevronDownIcon';
import TextAreaInput from 'components/text-area-input/TextAreaInput';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { CommonTranslations } from 'i18n/common.translations';

type ExpendableProps = {
  checked: boolean;
  label: string;
  expanded: boolean;
  example: string;
  disabled: boolean;
  onChange?: (value: string) => void;
  onExpandClick: () => void;
  onSubmit: (value: string) => void;
};

const TRANSLATIONS = defineMessages({
  seeExample: {
    id: 'ExpandableCompletionBlock.seeExample',
    defaultMessage: "Voir l'exemple",
  },
});

export default function ExpandableCompletionBlock({
  checked,
  label,
  expanded,
  example,
  disabled,
  onChange,
  onExpandClick,
  onSubmit,
}: ExpendableProps) {
  const { formatMessage } = useIntl();

  const [value, setValue] = useState('');
  const [showExample, setShowExample] = useState(false);

  return (
    <div
      className={classNames(
        'expandable-completion-block',
        { 'expandable-completion-block--disabled': disabled },
        { 'expandable-completion-block--expanded': expanded },
        { 'expandable-completion-block--checked': checked },
      )}
    >
      <button
        className="expandable-completion-block__header"
        onClick={onExpandClick}
        disabled={disabled || expanded}
      >
        <div className="expandable-completion-block__header__check">
          <CheckIcon />
        </div>
        <label>{label}</label>
        <ChevronDownIcon className="expandable-completion-block__header__chevron" />
      </button>

      <AnimatePresence>
        {expanded && (
          <motion.div
            style={{ display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'hidden' }}
            initial={{ marginTop: 0, height: 0 }}
            animate={{ marginTop: 16, height: 'auto' }}
            exit={{ marginTop: 0, height: 0 }}
          >
            <div className="expandable-completion-block__content">
              <TextAreaInput
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  onChange?.(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation(); // Prevents the form from capturing the "Enter" key, allowing the textarea to handle the line break
                  }
                }}
                placeholder={example}
              />
            </div>
            <div className="expandable-completion-block__footer">
              <div className="expandable-completion-block__footer__actions">
                <button
                  type="button" // Added to prevent the button from submitting the form (default type is "submit")
                  className={classNames('expandable-completion-block__footer__actions__see-example', {
                    'expandable-completion-block__footer__actions__see-example--active': showExample,
                  })}
                  onClick={() => setShowExample(!showExample)}
                >
                  <ChevronDownIcon />
                  {formatMessage(TRANSLATIONS.seeExample)}
                </button>
                <PrimaryButton
                  label={formatMessage(CommonTranslations.validate)}
                  onClick={() => onSubmit(value)}
                  smaller
                  disabled={value.length < 3}
                />
              </div>

              <AnimatePresence>
                {showExample && (
                  <motion.div
                    style={{ overflow: 'hidden' }}
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                  >
                    <p className="expandable-completion-block__footer__example">{example}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
