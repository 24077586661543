import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import { ComponentType, DetailedHTMLProps, useEffect, useState } from 'react';
import './TextAreaInput.scss';

// eslint-disable-next-line max-len
type DefaultInputProps = Pick<
  DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  'onChange' | 'value' | 'onFocus' | 'onBlur' | 'placeholder' | 'onKeyDown'
>;
type TextAreaInputProps = DefaultInputProps & {
  icon?: ComponentType<IconProps>;
  name?: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  info?: string;
  dataCy?: string;
};

export default function TextAreaInput({
  icon: Icon,
  name,
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  placeholder,
  error,
  disabled,
  info,
  dataCy,
}: TextAreaInputProps) {
  const [isFocused, setIsFocused] = useState(false);

  function handleFocus(event: any) {
    setIsFocused(true);
    onFocus?.(event);
  }

  function handleBlur(event: any) {
    setIsFocused(false);
    onBlur?.(event);
  }

  return (
    <div className="text-area-input">
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        className={classNames(
          'text-area-input__main',
          { 'text-area-input__main--focused': isFocused },
          { 'text-area-input__main--error': error },
          { 'text-area-input__main--disabled': disabled },
        )}
      >
        {Icon && <Icon className="text-area-input__main__icon" />}
        <textarea
          data-cy={dataCy}
          id={name}
          {...{
            name,
            value,
            placeholder,
            onChange,
            onKeyDown,
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </div>

      {info && <span className="text-area-input__info">{info}</span>}

      {error && <span className="text-area-input__error">{error}</span>}
    </div>
  );
}
