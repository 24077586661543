import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import { ambassadorPresentationValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { useState } from 'react';
import ExpandableCompletionBlock from 'components/expandable-completion-block/ExpandableCompletionBlock';

type FormikValues = {
  ambassadorPresentation: string;
  expertisePresentation: string;
  aspirationPresentation: string;
  interestsPresentation: string;
};

export default function AmbassadorPresentationPanel() {
  const intl = useIntl();

  const { user, updateUserCompletion } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const [currentEditingBlockIndex, setCurrentEditingBlockIndex] = useState(0);

  const formik = useFormik<FormikValues>({
    initialValues: {
      ambassadorPresentation: '',
      expertisePresentation: '',
      aspirationPresentation: '',
      interestsPresentation: '',
    },
    validationSchema: ambassadorPresentationValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      networkState.loading();
      await updateUser(values);
      networkState.succeeded();
    },
  });

  const sections = [
    {
      id: 'ambassador',
      label: user?.presentationTranslations?.ambassadorPresentationQuestion,
      example: user?.presentationTranslations?.ambassadorPresentationExample,
      fieldName: 'ambassadorPresentation',
    },
    {
      id: 'interest',
      label: user?.presentationTranslations?.interestsQuestion,
      example: user?.presentationTranslations?.interestsExample,
      fieldName: 'interestsPresentation',
    },
    {
      id: 'aspiration',
      label: user?.presentationTranslations?.aspirationsQuestion,
      example: user?.presentationTranslations?.aspirationsExample,
      fieldName: 'aspirationPresentation',
    },
    {
      id: 'expertise',
      label: user?.presentationTranslations?.expertisesQuestion,
      example: user?.presentationTranslations?.expertisesExample,
      fieldName: 'expertisePresentation',
    },
  ];

  const handleSubmit = (value: string) => {
    const currentField = sections[currentEditingBlockIndex].fieldName;
    formik.setFieldValue(currentField, value);
    setCurrentEditingBlockIndex((i) => Math.min(i + 1, sections.length));
  };

  const handleChange = (value: string) => {
    const currentField = sections[currentEditingBlockIndex].fieldName;
    switch (currentField) {
      case 'ambassadorPresentation':
        updateUserCompletion({ ambassadorPresentation: value });
        break;
      case 'expertisePresentation':
        updateUserCompletion({ expertisePresentation: value });
        break;
      case 'aspirationPresentation':
        updateUserCompletion({ aspirationPresentation: value });
        break;
      case 'interestsPresentation':
        updateUserCompletion({ interestsPresentation: value });
        break;
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
        {sections.map((section, index) => (
          <ExpandableCompletionBlock
            key={section.id}
            label={section.label || ''}
            checked={index < currentEditingBlockIndex}
            expanded={index === currentEditingBlockIndex}
            disabled={index > currentEditingBlockIndex}
            example={section.example || ''}
            onExpandClick={() => setCurrentEditingBlockIndex(index)}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
        ))}
      </div>

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.isValid}
      />
    </form>
  );
}
