import { createContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { AvailableLanguagesEnum, Get_SettingsQuery, Get_SettingsQueryVariables } from '../../@types/graphql';
import { GET_SETTINGS } from './SettingsContext.gql';
import { Outlet } from 'react-router-dom';
import { errorTracker } from 'error-tracker/error-tracker';

export const SettingsContext = createContext<Get_SettingsQuery | null>(null);

export function SettingsContextProvider({ language }: { language: string }) {
  const [settings, setSettings] = useState<Get_SettingsQuery | null>(null);

  useQuery<Get_SettingsQuery, Get_SettingsQueryVariables>(GET_SETTINGS, {
    variables: {
      locale: language === 'en' ? AvailableLanguagesEnum.En : AvailableLanguagesEnum.Fr,
    },
    onCompleted: (data) => {
      if (data) setSettings(data);
      if (data.currentUserV2?.id) errorTracker.userId = data.currentUserV2.id;
    },
  });

  return <SettingsContext.Provider value={settings}><Outlet /></SettingsContext.Provider>;
}
