import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import CheckboxInput from '../../../components/checkbox-input/CheckboxInput';
import { InteractionGroupKeyEnum, MeetingPreference } from '../../../@types/graphql';
import { useIntl } from 'react-intl';
import { CommonTranslations } from 'i18n/common.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { meetingPreferencesPanelValidationSchema } from './panels.validation';
import { MeetingPreferencesPanelTranslations } from './panels.translations';
import BadgeRadioMultiSelectInput from 'components/badge-radio-multi-select-input/BadgeRadioMultiSelectInput';
import BadgeRadioInput from 'components/badge-radio-input/BadgeRadioInput';

type FormikFields = {
  meetingPreferences: MeetingPreference[];
  acceptGroupConversations: boolean;
};

export default function MeetingPreferencesPanel() {
  const intl = useIntl();

  const { user } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikFields>({
    initialValues: {
      meetingPreferences: [],
      acceptGroupConversations: false,
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: meetingPreferencesPanelValidationSchema(intl),
    onSubmit: async ({ meetingPreferences, acceptGroupConversations }) => {
      networkState.loading();
      if (meetingPreferences) {
        await updateUser({
          meetingPreferences: meetingPreferences.map(({ key, value }) => ({
            key,
            accept: value || false,
          })),
          acceptGroupConversations,
        });
      }
      networkState.succeeded();
    },
  });

  const meetingPreferencesValues = useMemo(
    () =>
      (user?.meetingPreferences || []).map(({ key, name }) => ({
        value: key,
        translation: name,
      })),
    [user?.meetingPreferences],
  );
  
  const handleBadgeSelection = (updatedPreferences: { key: string; value: boolean }[]) => {
    formik.validateForm();
    formik.setFieldValue('meetingPreferences', updatedPreferences);
  };

  return (
    <form>
      <BadgeRadioMultiSelectInput
        name="meeting-preferences"
        label={intl.formatMessage(MeetingPreferencesPanelTranslations.meetingPreferencesLabel)}
        value={formik.values.meetingPreferences.map(({ key, value }) => ({ key, value: value || false }))}
        values={meetingPreferencesValues}
        onSelection={handleBadgeSelection}
      />

      {meetingPreferencesValues.some(({ value }) => value === InteractionGroupKeyEnum.InActivity) && (
        <p>{intl.formatMessage(MeetingPreferencesPanelTranslations.professionnalMention)}</p>
      )}

      <BadgeRadioInput
        name="accept-group-conversations"
        label={intl.formatMessage(MeetingPreferencesPanelTranslations.acceptGroupConversationLabel)}
        value={formik.values.acceptGroupConversations ? ['true'] : []}
        values={[{ value: 'true', translation: intl.formatMessage(MeetingPreferencesPanelTranslations.acceptGroupConversation) }]}
        onSelection={(values) => formik.setFieldValue('acceptGroupConversations', values.includes('true'))}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        onClick={formik.handleSubmit}
        disabled={!formik.isValid}
      />
    </form>
  );
}
