import { ComponentType } from 'react';
import './TextButton.scss';
import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';

type TextButtonProps = {
  label: string;
  content?: string;
  icon?: ComponentType<IconProps>;
  onClick: () => void;
  selected?: boolean;
};

export default function TextButton({ label, icon: Icon, onClick, content, selected }: TextButtonProps) {
  return (
    <button
      className={classNames("text-button", { "text-button--selected": selected })}
      type="button"
      onClick={onClick}
    >
      {Icon && <Icon className="text-button__icon" />}
      <span className="text-button__label">{label}</span>
      {content && <span className="text-button__content">{content}</span>}
    </button>
  );
}
