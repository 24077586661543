import { defineMessages } from 'react-intl';

export const PanelErrorTranslations = defineMessages({
  inputEmpty: {
    id: 'PanelErrorTranslations.inputEmpty',
    defaultMessage: 'Ce champ est obligatoire',
  },
  passwordMismatch: {
    id: 'PanelErrorTranslations.passwordMismatch',
    defaultMessage: 'Les mots de passe ne correspondent pas',
  },
  passwordTooWeak: {
    id: 'PanelErrorTranslations.passwordTooWeak',
    defaultMessage: "Le mot de passe n'est pas assez fort",
  },
  inputTooShort: {
    id: 'PanelErrorTranslations.inputTooShort',
    defaultMessage: 'Ce champ est trop court',
  },
  invalidPhoneNumber: {
    id: 'PanelErrorTranslations.invalidPhoneNumber',
    defaultMessage: 'Format de numéro de téléphone invalide',
  },
  startDateInFuture: {
    id: 'PanelErrorTranslations.startDateInFuture',
    defaultMessage: 'La date de début doit être dans le passé',
  },
  endDateInFuture: {
    id: 'PanelErrorTranslations.endDateInFuture',
    defaultMessage: 'La date de fin doit être dans le passé',
  },
  endDateBeforeStartDate: {
    id: 'PanelErrorTranslations.endDateBeforeStartDate',
    defaultMessage: 'La date de fin doit être après la date de début',
  },
  invalidGradeEnum: {
    id: 'PanelErrorTranslations.invalidGradeEnum',
    defaultMessage: "Niveau(x) d'étude(s) invalide(s)",
  },
  invalidJobTitle: {
    id: 'PanelErrorTranslations.invalidJobTitle',
    defaultMessage: 'Métier invalide',
  },
});

export const BirthdatePanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.BirthdatePanelTranslations.title',
    defaultMessage: 'Renseignez votre date de naissance',
  },
  subtitle: {
    id: 'ProfileCompletion.BirthdatePanelTranslations.subtitle',
    defaultMessage: 'Veuillez renseigner votre date de naissance.',
  },
  birthdate: {
    id: 'ProfileCompletion.BirthdatePanelTranslations.birthdate',
    defaultMessage: 'Date de naissance',
  },
});

export const IdentityPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.IdentityPanelTranslations.title',
    defaultMessage: 'Laissez-vous guider et complétez votre profil.',
  },
  subtitle: {
    id: 'ProfileCompletion.IdentityPanelTranslations.subtitle',
    defaultMessage:
      'Renseignez votre genre, votre nom et votre prénom afin de permettre à vos interlocuteurs de savoir à qui il s’adressent.',
  },
  gender: {
    id: 'ProfileCompletion.IdentityPanelTranslations.gender',
    defaultMessage: 'Genre',
  },
  genderPlaceholder: {
    id: 'ProfileCompletion.IdentityPanelTranslations.genderPlaceholder',
    defaultMessage: 'Sélectionnez votre genre',
  },
  firstName: {
    id: 'ProfileCompletion.IdentityPanelTranslations.firstName',
    defaultMessage: 'Prénom',
  },
  firstNamePlaceholder: {
    id: 'ProfileCompletion.IdentityPanelTranslations.firstNamePlaceholder',
    defaultMessage: 'Entrer votre prénom',
  },
  lastName: {
    id: 'ProfileCompletion.IdentityPanelTranslations.lastName',
    defaultMessage: 'Nom de famille',
  },
  lastNamePlaceholder: {
    id: 'ProfileCompletion.IdentityPanelTranslations.lastNamePlaceholder',
    defaultMessage: 'Entrer votre nom de famille',
  },
  avatar: {
    id: 'ProfileCompletion.IdentityPanelTranslations.avatar',
    defaultMessage: 'Votre photo de profil',
  },
});

export const PrimarySituationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.PrimarySituationPanelTranslations.title',
    defaultMessage: 'Dîtes-nous en plus sur votre situation professionnelle.',
  },
  subtitle: {
    id: 'ProfileCompletion.PrimarySituationPanelTranslations.subtitle',
    defaultMessage: 'Sélectionnez votre situation actuelle dans le menu déroulant.',
  },
  label: {
    id: 'ProfileCompletion.PrimarySituationPanelTranslations.label',
    defaultMessage: 'Votre situation actuelle.',
  },
  placeholder: {
    id: 'ProfileCompletion.PrimarySituationPanelTranslations.placeholder',
    defaultMessage: 'Sélectionnez une situation.',
  },
  codeInputLabel: {
    id: 'ProfileCompletion.PrimarySituationPanelTranslations.codeInputLabel',
    defaultMessage: 'Code programme (facultatif)',
  },
  codeInputValid: {
    id: 'ProfileCompletion.PrimarySituationPanelTranslations.codeInputValid',
    defaultMessage: 'Code programme valide : {programName}',
  },
});

export const ProfessorSituationTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.title',
    defaultMessage: 'Votre établissement et votre poste',
  },
  subtitle: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.subtitle',
    defaultMessage:
      'Renseignez l’établissement scolaire dans lequel vous travaillez actuellement ainsi que le rôle que vous occupez.',
  },
  secondarySituation: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.secondarySituation',
    defaultMessage: "Type d'établissement",
  },
  departmentLabel: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.departmentLabel',
    defaultMessage: "Département de l'établissement",
  },
  currentSchoolNameLabel: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.currentSchoolNameLabel',
    defaultMessage: 'Votre établissement',
  },
  currentSchoolNamePlaceholder: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.currentSchoolNamePlaceholder',
    defaultMessage: 'Entrez le nom ou la ville de votre établissement',
  },
  labelTeachedSchoolSubjects: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelCourse',
    defaultMessage: 'Matière enseignée',
  },
  labelProfession: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelProfession',
    defaultMessage: 'Votre métier',
  },
  accompaniedStudyLevels: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.studyLevelSupported',
    defaultMessage: "Niveaux d'études accompagnés",
  },
  labelPrincipalTeacher: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelPrincipalTeacher',
    defaultMessage: 'Etes-vous professeur principal ?',
  },
  labelOther: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.labelOther',
    defaultMessage: 'Précisez',
  },
  emptySchoolNameMessage: {
    id: 'ProfileCompletion.ProfessorSituationTranslations.emptySchoolNameMessage',
    defaultMessage: "Pas de résultat. Cliquez ici si vous n'avez pas trouvé votre établissement dans la liste",
  },
});

export const FranceTravailSupportSituationTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.title',
    defaultMessage: 'La structure dans laquelle vous travaillez',
  },
  subtitle: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.subtitle',
    defaultMessage:
      'Renseignez le type de structure dans laquelle vous travaillez ainsi que le ou les programme(s) suivi(s) par les demandeurs d’emploi concernés par cette démarche.',
  },
  organizationTypeLabel: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.organizationTypeLabel',
    defaultMessage: 'Type de structure dans laquelle vous travaillez',
  },
  labelOther: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelOther',
    defaultMessage: 'Si autre, précisez',
  },
  labelJobTitle: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelJobTitle',
    defaultMessage: 'Poste occupé',
  },
  labelCompanyName: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelCompanyName',
    defaultMessage: 'Nom de l’agence ou de la structure dans laquelle vous travaillez',
  },
  placeholderCompanyName: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.placeholderCompanyName',
    defaultMessage: 'Exemple : Agence France Travail Paris 12ème Diderot',
  },
  labelPrograms: {
    id: 'ProfileCompletion.FranceTravailSupportSituationTranslations.labelPrograms',
    defaultMessage: "Type d'accompagnement",
  },
});

export const SearchingForSituationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.SearchingForSituationPanelTranslations.title',
    defaultMessage: "Êtes-vous dans l'un des cas suivants ?",
  },

  jobSeekingLabel: {
    id: 'ProfileCompletion.SearchingForSituationPanelTranslations.jobSeekingLabel',
    defaultMessage: 'Je recherche un stage, une alternance ou un emploi',
  },
  returningToStudy: {
    id: 'ProfileCompletion.SearchingForSituationPanelTranslations.returningToStudy',
    defaultMessage: "Je suis en reprise d'études",
  },
});

export const SecondarySituationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.SecondarySituationPanelTranslations.title',
    defaultMessage: "Votre niveau d'études",
  },
  subtitle: {
    id: 'ProfileCompletion.SecondarySituationPanelTranslations.subtitle',
    defaultMessage: "Quel est votre niveau d'études actuel ?",
  },
});

export const ProfessionalSituationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.title',
    defaultMessage: 'Dîtes-nous en plus sur votre situation professionnelle',
  },
  subtitle: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.subtitle',
    defaultMessage: 'Renseignez les informations relatives à votre poste actuel.',
  },

  job: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.job',
    defaultMessage: 'Poste actuel',
  },
  jobPlaceholder: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.jobPlaceholder',
    defaultMessage: 'Renseignez votre poste actuel',
  },
  companyName: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.companyName',
    defaultMessage: "Nom de l'entreprise",
  },
  companyNamePlaceholder: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.companyNamePlaceholder',
    defaultMessage: 'Indiquez le nom de votre entreprise',
  },
  careerStartedAt: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.careerStartedAt',
    defaultMessage: "Date de début d'activité professionnelle",
  },
  careerStartedAtPlaceholder: {
    id: 'ProfileCompletion.ProfessionalSituationPanelTranslations.careerStartedAtPlaceholder',
    defaultMessage: 'Avril 1989',
  },
  yearsOfExperience: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.yearsOfExperience',
    defaultMessage: "Années d'expérience",
  },
  yearsOfExperiencePlaceholder: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.yearsOfExperiencePlaceholder',
    defaultMessage: 'Renseignez votre nombre d’années d’expérience',
  },
});

export const OtherSituationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.title',
    defaultMessage: 'Votre dernier emploi',
  },
  subtitle: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.subtitle',
    defaultMessage: 'Renseignez les informations suivantes sur le dernier poste que vous avez occupé.',
  },

  jobTitle: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.jobTitle',
    defaultMessage: 'Dernier poste occupé',
  },
  jobTitlePlaceholder: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.jobTitlePlaceholder',
    defaultMessage: 'Renseignez le dernier poste que vous avez occupé',
  },
  companyName: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.companyName',
    defaultMessage: "Nom de l'entreprise",
  },
  companyNamePlaceholder: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.companyNamePlaceholder',
    defaultMessage: 'Indiquez le nom de votre entreprise',
  },
  yearsOfExperience: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.yearsOfExperience',
    defaultMessage: "Années d'expérience",
  },
  yearsOfExperiencePlaceholder: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.yearsOfExperiencePlaceholder',
    defaultMessage: 'Renseignez votre nombre d’années d’expérience',
  },
  neverWorked: {
    id: 'ProfileCompletion.OtherSituationPanelTranslations.neverWorked',
    defaultMessage: "Je n'ai jamais travaillé",
  },
});

export const CurrentEducationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.title',
    defaultMessage: 'Dîtes-nous en plus sur la formation que vous suivez actuellement',
  },
  subtitle: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.subtitle',
    defaultMessage: 'Renseignez les informations relatives à votre établissement et votre niveau d’études actuel.',
  },
  currentGradeLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentGradeLabel',
    defaultMessage: "Niveau d'études",
  },
  currentGradePlaceholder: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentGradePlaceholder',
    defaultMessage: 'Sélectionnez un niveau d’études',
  },
  currentSchoolNameLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentSchoolNameLabel',
    defaultMessage: 'Votre établissement',
  },
  currentSchoolNamePlaceholder: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentSchoolNamePlaceholder',
    defaultMessage: 'Entrez le nom ou la ville de votre établissement',
  },
  currentSchoolCityLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentSchoolCityLabel',
    defaultMessage: 'Ville',
  },
  currentSchoolCityPlaceholder: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.currentSchoolCityPlaceholder',
    defaultMessage: 'Paris',
  },
  secondarySituation: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.secondarySituation',
    defaultMessage: "Type d'établissement",
  },
  secondarySituationPlaceholder: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.secondarySituationPlaceholder',
    defaultMessage: 'Sélectionnez un type d’établissement',
  },
  labelInputApprentice: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.labelInputApprentice',
    defaultMessage: 'Suivez-vous cette formation en alternance ?',
  },
  departmentLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.departmentLabel',
    defaultMessage: "Département de l'établissement",
  },
  departmentPlaceholder: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.departmentPlaceholder',
    defaultMessage: 'Sélectionnez un département',
  },
  diplomaLabel: {
    id: 'ProfileCompletion.CurrentEducationPanelTranslations.diplomaLabel',
    defaultMessage: 'Titre du diplôme préparé',
  },
  emptySchoolNameMessage: {
    id: 'Profile.CurrentEducationPanelTranslations.emptySchoolNameMessage',
    defaultMessage: "Pas de résultat. Cliquez ici si vous n'avez pas trouvé votre établissement dans la liste",
  },
});

export const PastEducationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.title',
    defaultMessage: "Votre niveau d'études",
  },
  subtitle: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.subtitle',
    defaultMessage: 'Renseignez les informations suivantes concernant votre plus haut niveau de diplôme',
  },

  pastEducationLevel: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.pastEducationLevel',
    defaultMessage: "Niveau d'études",
  },
  pastEducationLevelPlaceholder: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.pastEducationLevelPlaceholder',
    defaultMessage: 'Sélectionnez un niveau d’études',
  },
  pastDiplomaLabel: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.pastDiplomaLabel',
    defaultMessage: 'Intitulé du diplôme obtenu',
  },
  pastDiplomaPlaceholder: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.pastDiplomaPlaceholder',
    defaultMessage: 'Renseignez le nom du dipôme',
  },
  pastSchoolNameLabel: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.pastSchoolNameLabel',
    defaultMessage: 'Nom de établissement',
  },
  pastSchoolNamePlaceholder: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.pastSchoolNamePlaceholder',
    defaultMessage: 'Entrez le nom ou la ville de votre établissement',
  },
  periodEnd: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.periodEnd',
    defaultMessage: "Année d'obtention du diplôme",
  },
  periodPlaceholder: {
    id: 'ProfileCompletion.PastEducationPanelTranslations.periodPlaceholder',
    defaultMessage: 'Indiquez une année',
  },
});

export const WantToSharePanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.title',
    defaultMessage: 'Dîtes-nous ce que vous souhaitez faire sur My Job Glasses.',
  },
  titleStudent: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.titleStudent',
    defaultMessage: 'Partagez votre expérience en devenant Ambassadeur My Job Glasses.',
  },
  subtitleStudent: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.subtitleStudent',
    defaultMessage:
      "Vous ne vous en rendez peut être pas compte, mais vous avez déjà beaucoup à apporter à des plus jeunes que vous (stages, choix d'orientation, options...). Et si vous aussi vous vous engagiez en leur partageant votre expérience ?",
  },
  subtitlePro: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.subtitlePro',
    defaultMessage:
      "Votre expérience peut permettre à de nombreuses personnes (jeunes, demandeurs d'emploi, adultes en reconversion, seniors..) de trouver leur voie ou d'écrire la suite de leur carrière. Parce qu'une heure de votre temps peut faire la différence, rejoignez le mouvement en devenant Ambassadeur sur My Job Glasses !",
  },

  wantToPassStudentTitle: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.wantToPassStudentTitle',
    defaultMessage: '❌ Peut-être plus tard',
  },
  wantToPassTitle: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.wantToPassTitle',
    defaultMessage: '🔎 Rencontrer des Ambassadeurs',
  },
  wantToShareStudentTitle: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.wantToShareStudentTitle',
    defaultMessage: '🤝 Je deviens Ambassadeur',
  },
  wantToShareTitle: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.wantToShareTitle',
    defaultMessage: '🤝 Partager mon expérience',
  },
  wantToShareStudentContent: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.wantToShareStudentContent',
    defaultMessage: 'Je partage mon expérience avec des jeunes moins avancés que moi dans leur parcours.',
  },
  wantToShareContent: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.wantToShareContent',
    defaultMessage: 'Je partage mon expérience avec les Membres inscrits sur My Job Glasses.',
  },
  wantToPassStudentContent: {
    id: 'ProfileCompletion.WantToSharePanelTranslations.wantToPassStudentContent',
    defaultMessage: 'Je souhaite uniquement rencontrer des Ambassadeurs pour commencer.',
  },
});

export const MaxMeetingsPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.MaxMeetingsPanelTranslations.title',
    defaultMessage: 'Définissez le nombre de rendez-vous que vous souhaitez effectuer par mois',
  },
  subtitle: {
    id: 'ProfileCompletion.MaxMeetingsPanelTranslations.subtitle',
    defaultMessage:
      'Nous vous conseillons de commencer par 2 rendez-vous par mois, le temps de vous familiariser avec la plateforme. Vous pouvez cependant modifier cette limite (de 1 à 10 rendez-vous par mois), maintenant ou plus tard via l’édition de votre profil.',
  },
  help: {
    id: 'ProfileCompletion.MaxMeetingsPanelTranslations.help',
    defaultMessage: 'Vous serez contacté au maximum par {value} membres durant le mois.',
  },
  quota: {
    id: 'ProfileCompletion.MaxMeetingsPanelTranslations.quota',
    defaultMessage: 'Nombre de rendez-vous par mois',
  },
  quotaOption: {
    id: 'ProfileCompletion.MaxMeetingsPanelTranslations.quotaOption',
    defaultMessage: 'rendez-vous par mois',
  },
});

export const MeetingPreferencesPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.MeetingPreferencesPanelTranslations.title',
    defaultMessage: 'Choisissez les profils avec lesquels vous souhaitez échanger',
  },
  subtitle: {
    id: 'ProfileCompletion.MeetingPreferencesPanelTranslations.subtitle',
    defaultMessage: 'Seuls les profils que vous aurez sélectionnés pourront initier une conversation avec vous.',
  },
  acceptGroupConversation: {
    id: 'ProfileCompletion.MeetingPreferencesPanelTranslations.canAcceptGroupConversation',
    defaultMessage: 'Professeurs et conseillers à l’emploi',
  },
  acceptGroupConversationLabel: {
    id: 'ProfileCompletion.MeetingPreferencesPanelTranslations.canAcceptGroupConversationLabel',
    defaultMessage:
      "Pour effectuer des rendez-vous collectifs auprès de groupes de collégiens, de lycéens ou de demandeurs d'emploi (en présentiel ou en visioconférence)",
  },
  meetingPreferencesLabel: {
    id: 'ProfileCompletion.MeetingPreferencesPanelTranslations.meetingPreferencesLabel',
    defaultMessage: 'Pour effectuer des rendez-vous individuels',
  },
  professionnalMention: {
    id: 'ProfileCompletion.MeetingPreferencesPanelTranslations.professionnalMention',
    defaultMessage:
      '* Professionnels : Personnes en poste, à la recherche d’un emploi, à la retraite, en reconversion ou dans une autre situation',
  },
});

export const ContactPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.ContactPanelTranslations.title',
    defaultMessage: 'Indiquez vos coordonnées',
  },
  subtitle: {
    id: 'ProfileCompletion.ContactPanelTranslations.subtitle',
    defaultMessage:
      'Indiquez votre lieu de résidence ainsi que votre numéro de téléphone. Ce dernier ne sera visible que par les personnes que vous contactez.',
  },
  location: {
    id: 'ProfileCompletion.ContactPanelTranslations.location',
    defaultMessage: 'Ville',
  },
  locationPlaceholder: {
    id: 'ProfileCompletion.ContactPanelTranslations.locationPlaceholder',
    defaultMessage: 'Entrez le nom de votre ville',
  },
  phone: {
    id: 'ProfileCompletion.ContactPanelTranslations.phone',
    defaultMessage: 'Numéro de téléphone',
  },
});

export const LanguagesPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.LanguagesPanelTranslations.title',
    defaultMessage: 'Quelles langues parlez-vous ?',
  },
  subtitle: {
    id: 'ProfileCompletion.LanguagesPanelTranslations.subtitle',
    defaultMessage: 'Indiquez les langues dans lesquelles vous êtes en mesure de communiquer sur la plateforme.',
  },
  languages: {
    id: 'ProfileCompletion.LanguagesPanelTranslations.languages',
    defaultMessage: 'Langue(s)',
  },
  placeholder: {
    id: 'ProfileCompletion.LanguagesPanelTranslations.placeholder',
    defaultMessage: 'Sélectionnez une ou plusieurs langues',
  },
});

export const AvatarPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.AvatarPanelTranslations.title',
    defaultMessage: 'Choisissez votre photo de profil',
  },
  subtitle: {
    id: 'ProfileCompletion.AvatarPanelTranslations.subtitle',
    defaultMessage:
      'Choisissez une photo de bonne qualité. Une photo est essentielle pour rendre votre profil plus attrayant et encourager vos interlocuteurs à vous répondre.',
  },
});

export const MemberPresentationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.MemberPresentationPanelTranslations.titleStudent',
    defaultMessage: 'Renseignez votre présentation Membre.',
  },
  subtitle: {
    id: 'ProfileCompletion.MemberPresentationPanelTranslations.subtitleStudent',
    defaultMessage:
      'Cette présentation ne sera visible que par les Ambassadeurs que vous contactez. En la lisant, ils pourront en savoir plus sur votre parcours et mieux comprendre votre démarche.',
  },
  titleSupport: {
    id: 'ProfileCompletion.MemberPresentationPanelTranslations.titleSupport',
    defaultMessage: 'Votre profil et votre démarche en quelques lignes',
  },
  subtitleSupport: {
    id: 'ProfileCompletion.MemberPresentationPanelTranslations.subtitleSupport',
    defaultMessage:
      'Renseignez ici votre présentation. Cela permettra aux ambassadeurs que vous contactez de savoir qui vous êtes et de mieux comprendre votre démarche.',
  },
  placeholder: {
    id: 'ProfileCompletion.MemberPresentationPanelTranslations.placeholder',
    defaultMessage: 'Complétez votre présentation',
  },
});

export const AmbassadorPresentationPanelTranslations = defineMessages({
  title: {
    id: 'ProfileCompletion.AmbassadorPresentationPanelTranslations.title',
    defaultMessage: 'Renseignez votre présentation Ambassadeur',
  },
  subtitle: {
    id: 'ProfileCompletion.AmbassadorPresentationPanelTranslations.subtitle',
    defaultMessage:
      'Cette présentation est celle qui apparaîtra sur votre profil public. Elle est destinée aux Membres qui vous trouveront via le moteur de recherche et cliqueront sur votre carte.',
  },
});
