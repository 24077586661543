import Layout from 'components/layout/Layout';
import HrAdminSignupForm from './components/hr-admin-signup-form/HrAdminSignupForm';
import StickyDescriptionOffer from './components/sticky-description-offer/StickyDescriptionOffer';
import ContactAppointment from './components/contact-appointment/ContactAppointment';
import { useResponsiveComponent } from 'hooks/responsive-component.hook';
import { memo, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import HeaderAdminSignup from './components/header-admin-signup/HeaderAdminSignup';
import ahoy from 'utils/ahoy_api';

import './HrAdminSignupView.scss';

export default function HrAdminSignupView() {
  const ResponsiveComponent = useResponsiveComponent({
    desktop: Desktop,
    mobile: Mobile,
  });

  useEffect(() => {
    trackPageView();
    // Note we are using the function declaration itself to prevent double tracking on rerender
  }, [trackPageView]);

  return <ResponsiveComponent />;
}
const Desktop = memo(() => {
  const formRef = useRef<HTMLDivElement>(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (isFormCompletedFromUrl(searchParams)) {
      scrollToForm();
    }
  }, []);

  return (
    <Layout
      withFullScreenImage
      withCompanySignUpBtn
    >
      <HeaderAdminSignup
        onFreeButtonClick={() => {
          trackFreeButtonClick();
          scrollToForm();
        }}
      />
      <main className="hr-admin-signup-view">
        <div
          className="hr-admin-signup-view__form"
          ref={formRef}
        >
          <HrAdminSignupForm />
        </div>
        <div className="hr-admin-signup-view__sticky-container">
          <StickyDescriptionOffer />
          <ContactAppointment />
        </div>
      </main>
    </Layout>
  );
});

const Mobile = memo(() => {
  const formRef = useRef<HTMLDivElement>(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (isFormCompletedFromUrl(searchParams)) {
      scrollToForm();
    }
  }, []);

  return (
    <Layout
      withFullScreenImage
      withCompanySignUpBtn
    >
      <HeaderAdminSignup
        mobileView
        onFreeButtonClick={() => {
          trackFreeButtonClick();
          scrollToForm();
        }}
      />
      <main className="hr-admin-signup-view">
        <div className="hr-admin-signup-view__sticky-container__offer">
          <StickyDescriptionOffer />
        </div>
        <div
          className="hr-admin-signup-view__form"
          ref={formRef}
        >
          <HrAdminSignupForm />
        </div>
        <div className="hr-admin-signup-view__sticky-container__contact">
          <ContactAppointment />
        </div>
      </main>
    </Layout>
  );
});

const isFormCompletedFromUrl = (searchParams: URLSearchParams) => searchParams.has('email');

const trackPageView = () => {
  ahoy.track('b2b_company_viewed_signup_page', {});
};

const trackFreeButtonClick = () => {
  ahoy.track('b2b_company_signup_clicked_start_free_version', {});
};
