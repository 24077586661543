import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { GenderTranslations } from 'i18n/gender.translations';
import { useIntl } from 'react-intl';
import { GenderEnum } from '../../../@types/graphql';
import formikError from '../../../components/formik-helper/formik.helper';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import SelectInput from '../../../components/select-input/SelectInput';
import TextInput from '../../../components/text-input/TextInput';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import { IdentityPanelTranslations } from './panels.translations';
import { identityPanelValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import AvatarInput from 'components/avatar-input/AvatarInput';
import { useEffect } from 'react';

function getSavedInformations(email?: string): {
  firstName: string;
  lastName: string;
} {
  const object = { firstName: '', lastName: '' };
  if (!email) return object;
  const savedString = localStorage.getItem(email);
  if (!savedString) return object;
  return JSON.parse(savedString);
}

type FormikValues = {
  gender?: GenderEnum;
  avatar?: File;
  firstName: string;
  lastName: string;
  programParticipationCode?: string;
};

export default function IdentityPanel() {
  const intl = useIntl();

  const { user, updateUserCompletion } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();

  const savedInformations = getSavedInformations(user?.email);
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      gender: undefined,
      firstName: user?.firstName || savedInformations?.firstName || '',
      lastName: user?.lastName || savedInformations?.lastName || '',
      avatar: undefined,
      programParticipationCode: localStorage.getItem('program_code') || undefined,
    },
    validationSchema: identityPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ gender, firstName, lastName, programParticipationCode, avatar }) => {
      networkState.loading();
      await updateUser({
        gender,
        firstName,
        avatar,
        lastName,
        programParticipationCode,
      });
      if (user) localStorage.removeItem(user.email!);
      networkState.succeeded();
    },
  });

  useEffect(() => {
    updateUserCompletion({
      avatars: formik.values.avatar ? { url: URL.createObjectURL(formik.values.avatar) } : undefined,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
    });
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <AvatarInput
        onChange={(file) => formik.setFieldValue('avatar', file)}
        label={intl.formatMessage(IdentityPanelTranslations.avatar)}
        small
      />
      <SelectInput
        dataCy="gender"
        name="gender"
        label={intl.formatMessage(IdentityPanelTranslations.gender)}
        values={Object.values(GenderEnum).map((gender) => ({
          value: gender,
          translation: intl.formatMessage(GenderTranslations[gender]),
        }))}
        value={formik.values.gender}
        onChange={(gender) => formik.setFieldValue('gender', gender)}
        placeholder={intl.formatMessage(IdentityPanelTranslations.genderPlaceholder)}
        error={formikError(formik.touched, formik.errors, 'gender')}
      />

      <TextInput
        dataCy="first-name"
        name="firstName"
        label={intl.formatMessage(IdentityPanelTranslations.firstName)}
        placeholder={intl.formatMessage(IdentityPanelTranslations.firstNamePlaceholder)}
        value={formik.values.firstName}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'firstName')}
      />

      <TextInput
        dataCy="last-name"
        name="lastName"
        label={intl.formatMessage(IdentityPanelTranslations.lastName)}
        placeholder={intl.formatMessage(IdentityPanelTranslations.lastNamePlaceholder)}
        value={formik.values.lastName}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'lastName')}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.isValid}
      />
    </form>
  );
}
