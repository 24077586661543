import { gql } from '@apollo/client';

export const SIGN_IN_MUTATION = gql`
  mutation SIGN_IN($email: Email!, $password: String!, $draftAttributionToken: String) {
    signin(email: $email, password: $password, draftAttributionToken: $draftAttributionToken) {
      email
      companyCreationRequest {
        id
        statusCd
      }
      companyAdminProfiles {
        id
      }
      isCoach
    }
  }
`;
